import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from 'src/app/services/jrni/member.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  submitted: boolean;
  member;

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private memberService: MemberService,
    private spinner: NgxSpinnerService
  ) { }

  // Initialise the form
  changePasswordForm: FormGroup = new FormGroup({
    current_password: new FormControl('', Validators.required),
    new_password: new FormControl('', Validators.required),
    confirm_new_password: new FormControl('', Validators.required)
  });

  get form() { return this.changePasswordForm.controls; }

  ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
    });
  }

  // Submit the form update
  onSubmit() {
    this.submitted = true;
    this.spinner.show();

    if (this.changePasswordForm.invalid) {
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      // Scroll to the top of the page if form is invalid
      this.scrollToTop();
      this.spinner.hide();
      return;
    } else {
      if (this.form.new_password.value === this.form.confirm_new_password.value) {
        this.memberService.updatePassword(this.member, this.changePasswordForm.value).then(res => {
          this.alertService.success(this.translateService.instant('ACCOUNT.UPDATE_PASSWORD_SUCCESS'))
          this.scrollToTop();
          this.spinner.hide();
        }).catch((error: HttpErrorResponse) => {
          if (error['response'] && error['response'].data.details[0] === "current_password doesn't match existing password") {
            this.alertService.error(this.translateService.instant('ACCOUNT.CURRENT_PASSWORD_NO_MATCH'));
          } else {
            this.alertService.error(this.translateService.instant('ACCOUNT.UPDATE_PASSWORD_FAILED'))
          }
          this.scrollToTop();
          this.spinner.hide();
        });
      } else {
        this.alertService.warn(this.translateService.instant('ACCOUNT.PASSWORD_MATCH_ERR'));
        this.scrollToTop();
        this.spinner.hide();
      }
    }
  }

  // Scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }
}
