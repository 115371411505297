import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepartmentsComponent } from './journey/departments/departments.component';
import { LoginComponent } from './journey/login/login.component';
import { ServicesComponent } from './journey/services/services.component';
import { LocationComponent } from './journey/location/location.component';
import { CalendarComponent } from './journey/calendar/calendar.component';
import { BookingDetailsComponent } from './journey/booking-details/booking-details.component';
import { SummaryComponent } from './journey/summary/summary.component';
import { ConfirmationComponent } from './journey/confirmation/confirmation.component';
import { RegisterComponent } from './journey/register/register.component';
import { MainAccountComponent } from './account-journey/main-account/main-account.component';
import { GuardService } from './services/guards/guard.service';
import { AccountLoginComponent } from './account-journey/account-login/account-login.component';
import { AccountGuardService } from './services/guards/account-guard.service';
import { PaymentComponent } from './journey/payment/payment.component'
import { VerifyPaymentComponent } from './journey/verify-payment/verify-payment.component';
import { BlockBookingConfirmationComponent } from './journey/block-booking-confirmation/block-booking-confirmation.component';
import { BlockPurchaseReviewComponent } from './journey/block-purchase-review/block-purchase-review.component';

const routes: Routes = [
  { path: '', redirectTo: '/locations', pathMatch: 'full' },
  { path: 'locations', component: LocationComponent, data: { breadcrumb: 'Locations' } },
  { path: 'services', component: ServicesComponent, data: { breadcrumb: 'Options' } },
  { path: 'calendar', component: CalendarComponent, data: { breadcrumb: 'Availability' } },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login/Register' } },
  { path: 'register', component: RegisterComponent, data: { breadcrumb: 'Register' }  },
  { path: 'booking-details', component: BookingDetailsComponent, data: { breadcrumb: 'Booking Details' }, canActivate: [GuardService] },
  { path: 'summary', component: SummaryComponent, data: { breadcrumb: 'Review' }, canActivate: [GuardService] },
  { path: 'payment', component: PaymentComponent, data: { breadcrumb: 'Payment' }, canActivate: [GuardService] },
  { path: 'block-booking-confirmation', component: BlockBookingConfirmationComponent, data: { breadcrumb: 'Confirmation' }, canActivate: [GuardService] },
  { path: 'confirmation', component: ConfirmationComponent, data: { breadcrumb: 'Confirmation' }, canActivate: [GuardService] },
  { path: 'account', component: MainAccountComponent, canActivate: [AccountGuardService] },
  { path: 'account-login', component: AccountLoginComponent },
  { path: 'payment-verify', component: VerifyPaymentComponent },
  { path: 'review-booking', component: BlockPurchaseReviewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
