import { Component } from '@angular/core';
import { context } from 'bookingbug-core-js';
import { AppConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sports Pitch Booking Service';

  constructor(private translate: TranslateService, private router: Router) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    // BB Context storage
    context.apiUrl = AppConfig.settings.apiServer.api_url;
    context.apiVersion = AppConfig.settings.apiServer.version;
    context.appId = AppConfig.settings.core.app_id;
    context.appKey = null;
    
    // storage event to modify localStorage
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
           let token = localStorage.getItem('token_name');
           if(token == undefined) { 
             // Perform logout
             //Navigate to homepage
              this.router.navigate(['/services']); 
           }
      }
    });
  } 
}
