import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../jrni/login.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  canActivate() {
    if (this.loginService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
