import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingService } from '../services/jrni/booking.service';
import { AlertService } from '../_alert';
import { AvailabilityService } from '../services/jrni/availability.service';
import * as moment from 'moment';
import { BasketService } from '../services/jrni/basket.service';
import { EmailService } from '../services/email.service';
import { DepartmentService } from '../services/jrni/department.service'
import { DatastoreService } from 'src/app/services/datastore.service';

interface DialogData {
  booking,
  location,
  action,
  service,
  member,
  header
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  cancelMessage = true;
  cancelPromptMessage = false;
  cancelPassed: boolean;
  modifyPassed: boolean;
  slotEmpty = false;
  selectedSlot: Date;
  company;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bookingService: BookingService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private availabilityService: AvailabilityService,
    private basketService: BasketService,
    private emailService: EmailService,
    private departmentService: DepartmentService,
    private datastoreService: DatastoreService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // Mat calendar into accounts mode (removes duration)
    this.datastoreService.isAccounts = true;
    this.datastoreService.accountBooking = this.data['booking'];

    this.departmentService.getParentDepartment().then((department) => {
      this.company = department
    });
    if (this.data['booking'].canCancel()) {
      this.cancelPassed = false;
      this.modifyPassed = false;
    } else {
      this.cancelMessage = false;
      this.cancelPassed = true;
      this.modifyPassed = true;
    }
  }

  continue() {
    this.cancelMessage ? this.cancelMessage = false : this.cancelMessage = true;
    this.cancelPromptMessage ? this.cancelPromptMessage = false : this.cancelPromptMessage = true;
  }

  cancelBooking(member, booking) {
    // find blocks and remove
    this.bookingService.findBookingBlocks(booking).then(blockRes => {
      blockRes['blocked_bookings'].forEach(blockBooking => {
        this.bookingService.cancelBlock(blockBooking);
      });
    }).then(() => {
      // get booking and cancel
      this.bookingService.cancelBooking(member, booking).then(res => {
        this.dialogRef.close();
        this.alertService.success(this.translateService.instant('ACCOUNT.CANCEL_SUCCESS'))
        this.emailService.sendEmail("CANCEL", booking, null, this.company).subscribe((res) => { })
      })
    }).catch((error: HttpErrorResponse) => {
      console.error(error);
      this.dialogRef.close();
      this.alertService.error(this.translateService.instant('ACCOUNT.CANCEL_FAILED'))
      this.spinner.hide();
    });
  }

  modifyBooking(member, booking) {
    this.bookingService.cancelBooking(member, booking).then(res => {
      this.emailService.sendEmail("AMEND", booking, null, this.company).subscribe((res) => { })
      this.dialogRef.close();
      this.alertService.success(this.translateService.instant('ACCOUNT.CANCEL_SUCCESS'))
    }).catch((error: HttpErrorResponse) => {
      this.dialogRef.close();
      this.alertService.error(this.translateService.instant('ACCOUNT.CANCEL_FAILED'))
      this.spinner.hide();
    });
  }

  selectedSlotCheck(slotUpdated: boolean) {
    if (slotUpdated) {
      this.availabilityService.getSelectedSlot().then(slot => {
        if (slot) {
          this.slotEmpty = false;
          this.selectedSlot = slot.datetime;
        } else {
          this.slotEmpty = true;
        }
      });
    }
  }

  // Reschedule the booking
  confirmAmend(booking, slot, member) {
    this.alertService.clear();
    const originalDateTime = booking.datetime;
    const originalBooking = booking;

    this.spinner.show();
    if (booking) {
      // booking.datetime = moment(slot);
      let newBookingTime = moment(slot);
      this.slotEmpty = false;

      // get duration
      let duration = booking.duration;

      // if is in seconds, convert to minutes (for over 15 minutes)
      if(booking.duration > 900){
        duration = moment.duration(booking.duration, 'seconds').asMinutes();;
      } 

      booking.duration = duration; // booking.duration in minutes

      new Promise(async () => {
        this.bookingService.findBookingBlocks(originalBooking).then(blockRes => {
          if (blockRes['blocked_bookings'].length == 0) {
            this.bookingService.rescheduleBooking(booking, newBookingTime).then(bookingRes => {
              this.emailService.sendEmail("MOVED", booking, null, this.company);
              booking.datetime = newBookingTime
              this.alertService.success(this.translateService.instant('ACCOUNT.MODIFY_SUCCESS'))
              this.spinner.hide();
              this.dialogRef.close();
            });
            Promise.resolve();
          }
          blockRes['blocked_bookings'].forEach((blockBooking, index) => {
            // pass in the block, the new time and the duration
            this.bookingService.moveBlockedBooking(blockBooking, newBookingTime.toISOString(), duration).then((res) => {
              if (index == (blockRes['blocked_bookings'].length - 1)) {
                this.bookingService.rescheduleBooking(booking, newBookingTime).then(bookingRes => {
                  this.emailService.sendEmail("MOVED", booking, null, this.company);
                  booking.datetime = newBookingTime
                  this.alertService.success(this.translateService.instant('ACCOUNT.MODIFY_SUCCESS'))
                  this.spinner.hide();
                  this.dialogRef.close();
                  Promise.resolve();
                });
              }
            });
          });
        })
      }).catch((error: HttpErrorResponse) => {
        console.log(error);
        booking.datetime = originalDateTime;
        this.dialogRef.close();
        this.spinner.hide();
        this.alertService.error(this.translateService.instant('ACCOUNT.MODIFY_FAILED'))
      });
    } else {
      this.slotEmpty = true;
      return;
    }
  }
}
