import { Component, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { DatastoreService } from 'src/app/services/datastore.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { CapitaService } from 'src/app/services/jrni/capita.service';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { context } from 'bookingbug-core-js';
import { BookingService } from 'src/app/services/jrni/booking.service';
import { EmailService } from 'src/app/services/email.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MemberService } from 'src/app/services/jrni/member.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/jrni/api.service';

@Component({
  selector: 'app-verify-payment',
  templateUrl: './verify-payment.component.html',
  styleUrls: ['./verify-payment.component.scss']
})
export class VerifyPaymentComponent implements OnInit {

  scpId: any = null;
  bookingId: any = null;
  paymentId: any = null;
  companyId: any = null;
  pageTitle: string;
  verifyPageText: string;
  selectedSlot: any;
  selectedService: any;
  selectedLocation: any;
  purchase: any;
  selectedBooking: any;
  company: any;
  booking: any;
  member: any;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private dataStore: DatastoreService,
    private capitaService: CapitaService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private servicesService: ServicesService,
    private basketService: BasketService,
    private http: HttpClient,
    private bookingService: BookingService,
    private emailService: EmailService,
    private memberService: MemberService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.spinner.show();

    this.dataStore.checkShouldHideNavigation();

    this.route.queryParams.subscribe(params => {
      this.bookingId = params['b'];
      this.paymentId = params['p'];
      this.companyId = params['c'];
      this.pageTitle = 'Verifying payment';

      this.departmentService.getParentDepartment().then(dept => {
        this.departmentService.getChildDepartments(dept).then(async (children) => {
          for (const location of children) {
            if (location.id == this.companyId) {
              this.company = location;
              break;
            }
          }
          if (localStorage.booking) {
            const bookingData = JSON.parse(localStorage.booking);
            const member = await this.memberService.getMember();
            this.member = member;
            const booking = await this.bookingService.getBooking(member, bookingData.id);
            this.booking = booking;
            // set some stuff in the datastore
            this.dataStore.selectedLocation = this.company;
            this.dataStore.booking = booking;
            this.dataStore.selectedService = await booking.$getService();
            this.verifyPayment(member, booking, bookingData, this.company);
          }
          else {
            this.router.navigate(['/']);
          }
        }).catch(err => {
          console.log(err);
          if (this.booking) {
            this.apiService.logPaymentExtra(
              this.company,
              this.dataStore.selectedService,
              this.booking.purchase_id,
              {
                "purchase": this.booking.purchase_id,
                "dept": dept,
                "child_depts_error": err
              }
            );
          }
          else {
            this.apiService.logPaymentExtra(
              this.company,
              (this.dataStore.selectedService) ? this.dataStore.selectedService : { id: 0 },
              this.paymentId,
              {
                "member": this.member.id,
                "booking": this.bookingId,
                "dept": dept,
                "child_depts_error": err
              }
            );
          }
          this.spinner.hide();
          this.alertService.error(this.translateService.instant('PAYMENT.ERROR'));
        });
      }).catch(err => {
        console.log(err);
        this.apiService.logPaymentExtra(
          this.company,
          this.dataStore.selectedService,
          this.booking.purchase_id,
          {
            "purchase": this.booking.purchase_id,
            "parent_company_error": err
          }
        );
        this.spinner.hide();
        this.alertService.error(this.translateService.instant('PAYMENT.ERROR'));
      });
      
    });
  }

  async verifyPayment(member: any, booking: any, bookingData: any, company: any) {
    let port = '';
    try {
      const ports: any = ['80', '443'];
      if (ports.indexOf(window.location.port) === -1) {
        port = `:${window.location.port}`;
      }
      const url = `${window.location.protocol}//${window.location.hostname}${port}`;
      const path = '/'; // Use / for now. Eventually once we productise this, we'll use the customer name.
      const urlPath = `${url}${path}`;

      if (!this.scpId) {
        this.scpId = localStorage.getItem('scp');
      }

      if (!this.scpId) {
        this.apiService.logPaymentExtra(
          company,
          this.dataStore.selectedService,
          booking.purchase_id,
          {
            "purchase": booking.purchase_id,
            "missing_scp": true
          }
        );
        this.spinner.hide();
        this.alertService.error(this.translateService.instant('PAYMENT.ERROR'));
        return;
      }
      else {
        const verify = await this.capitaService.query(this.scpId);
        if (verify['state'] === 'INVALID_REFERENCE') {
          this.apiService.logPaymentExtra(
            company,
            this.dataStore.selectedService,
            booking.purchase_id,
            {
              "purchase": booking.purchase_id,
              "invalid_reference": verify
            }
          );
          this.spinner.hide();
          this.alertService.error(this.translateService.instant('PAYMENT.INVALID_REF'));
          return;
        }

        // check if we have payment details.
        try {
          
          booking.datetime = new Date(booking.datetime);
          booking.end_datetime = new Date(booking.end_datetime);
          if (!verify['ref']) {
            // we do, did we cancel?
            if (verify['state'] === 'COMPLETE') {
              await this.cancelBooking(member, booking);
            }
            else {
              // cancel the booking
              await this.cancelBooking(member, booking);
            }
            this.router.navigate(['locations']);
            return;
          }

          // get the slot
          this.selectedSlot = JSON.parse(localStorage.getItem('selectedSlot'));

          if (verify['ref']) {
            if (verify['state'] === 'COMPLETE') {
              // complete booking
                // send to confirmation page
                this.apiService.logPaymentExtra(
                  company,
                  this.dataStore.selectedService,
                  booking.purchase_id,
                  {
                    "purchase": booking.purchase_id,
                    "redirect_to_confirm": verify
                  }
                );

                const bookingType = JSON.parse(sessionStorage.getItem("bookingType"));
                const totalPrice = parseInt(verify['amount']);
                this.dataStore.totalPrice = totalPrice;
                if(bookingType == "block"){
                  this.router.navigate(['/block-booking-confirmation']);
                } else {
                  this.router.navigate(['confirmation']);
                }
                return;
            }
          }
          setTimeout(() => {
            this.verifyPayment(member, booking, bookingData, company);
          }, 1000);
        }
        catch (err) {
          this.spinner.hide();
          this.apiService.logPaymentExtra(
            this.company,
            this.dataStore.selectedService,
            booking.purchase_id,
            {
              "purchase": booking.purchase_id,
              "payment_error": err
            }
          );
          this.alertService.error(this.translateService.instant('PAYMENT.ERROR'));
          this.router.navigate(['locations']);
          return;
        }
      }
    }
    catch (err) {
      this.apiService.logPaymentExtra(
        this.company,
        this.dataStore.selectedService,
        this.booking.purchase_id,
        {
          "purchase": booking.purchase_id,
          "payment_verify_error": err
        }
      );
    }
  }

  cancelBooking(member, booking) {
    // find blocks and remove
    this.bookingService.findBookingBlocks(booking).then(blockRes => {
      blockRes['blocked_bookings'].forEach(blockBooking => {
        this.bookingService.cancelBlock(blockBooking);
      });
    }).then(() => {
      // get booking and cancel
      this.bookingService.cancelMultiBookings(booking).then(res => {
        // this.alertService.success(this.translateService.instant('ACCOUNT.CANCEL_SUCCESS'))
        // this.emailService.sendEmail("CANCEL", booking, null, this.company).subscribe((res) => { })
      });
    }).catch((error: HttpErrorResponse) => {
      console.log(error);
      // this.alertService.error(this.translateService.instant('ACCOUNT.CANCEL_FAILED'))
      this.spinner.hide();
    });
  }

}