import { Component, OnInit } from '@angular/core';
import { MemberService } from '../services/jrni/member.service';
import { Router } from '@angular/router';
import { LoginService } from '../services/jrni/login.service';

@Component({
  selector: 'app-member-bar',
  templateUrl: './member-bar.component.html',
  styleUrls: ['./member-bar.component.scss']
})
export class MemberBarComponent implements OnInit {
  member;
  name;
  currentLink: String;
  loggedIn = false;

  constructor(
    private memberService: MemberService,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
      if (member) {
        this.loggedIn = true;
        member.first_name ? this.name = member.first_name : this.name = member.last_name;
      }

      this.currentLink = this.router.url;
    });

    this.memberService.getMemberLoggedIn().subscribe((member: any) => {

      this.currentLink = this.router.url;

      if (member === null) {
        this.loggedIn = false;
      } else {
        this.loggedIn = true;
      }

      this.member = member;
    })
  }

  async logout() {
    await this.memberService.setMember(null);
    this.loginService.logout();
  }

  // same as logout but for the accounts button to redirect
  async signOut() {
    await this.memberService.setMember(null);
    this.loginService.logout();
    window.location.href = "https://www.lbhf.gov.uk/sport/sports-bookings-and-facilities";
  }


  resetLink() {
    this.currentLink = "locations";
    this.router.navigate(['/locations'])
  }
}
