import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DobValidatorService {

  constructor() { }

  isChildDOBValid(dateOfBirth) {
    // If DOB has data then check if the DOB is equal or greater than 18 years old else return false
    return this.isDate18orMoreYearsOld(dateOfBirth.getDate(), dateOfBirth.getMonth(), dateOfBirth.getFullYear());
  }

  isAdultDOBValid(dateOfBirth) { 
    // If DOB has data then check if the DOB is equal or greater than 18 years old else return false
    return this.isDate18orMoreYearsOld(dateOfBirth.getDate(), dateOfBirth.getMonth(), dateOfBirth.getFullYear());
  }

  isDate18orMoreYearsOld(day, month, year) {
    return new Date(year + 18, month, day) <= new Date();
  }
}
