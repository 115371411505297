import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { AlertService } from 'src/app/_alert';
import * as moment from 'moment';
import * as _ from 'lodash';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  member;
  customerQuestions;
  answers;
  registrationOption = false;
  submitted: boolean;
  dateQuestions: any = [];

  @ViewChild('day', { static: false }) day: ElementRef | undefined;
  @ViewChild('month', { static: false }) month: ElementRef | undefined;
  @ViewChild('year', { static: false }) year: ElementRef | undefined;

  constructor(
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  // Initialise the form
  memberDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.minLength(5)]),
    address1: new FormControl('', Validators.required),
    address2: new FormControl('', Validators.required),
    address4: new FormControl('', Validators.required),
    country: new FormControl(''),
    postcode: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required)
  });

  get form() { return this.memberDetailsForm.controls; }

  async ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
      // Set the customer answers
      this.answers = this.member.q;
      // Once the member has loaded then set the email and disable the control
      this.form.email.setValue(this.member.email);
      this.form.email.disable();
      // Set member attributes
      this.setMemberDetails();

      this.loadQuestions();

      this.openCalender()
    });
  }

  // Requests the customer questions from the department
  loadQuestions() {
    this.departmentService.getParentDepartment().then(parentCompany => {
      // Dont set parent company as selected department as this breaks the rest of it

      // Get the customer questions
      this.departmentService.getCustomerQuestions(parentCompany).then(questions => {
        this.customerQuestions = questions;

        for (let index = 0; index < this.customerQuestions.length; index++) {
          const question = this.customerQuestions[index];
          question.visible = false;
          question.reference = question.id;
          if (!question.admin_only) {
            if (!question.settings.hasOwnProperty('conditional_question')) {
              question.visible = true;
            }

            const questionControl = question.id;
            const id = question.id;
            if (question.default == 1 && question.detail_type == 'check' || question.default == 1 && question.detail_type == 'check-price') {
              if (question.required) {
                this.memberDetailsForm.addControl(questionControl, new FormControl(true, Validators.required));
              } else {
                this.memberDetailsForm.addControl(questionControl, new FormControl(true));
              }
            } else if (question['detail_type'] == 'date') {
              question.day_id = `${id}_day`;
              if (question['required']) {
                // Validators.required
                this.memberDetailsForm.addControl(`${id}_day`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(31)]));
                this.memberDetailsForm.addControl(`${id}_month`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(12)]));
                this.memberDetailsForm.addControl(`${id}_year`, new FormControl('', [Validators.required, Validators.min(1900), Validators.maxLength(4)]));

                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_day`]);
                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_month`]);
                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_year`]);
                this.dateQuestions.push(id);

                // repopulate the date questions
                const dob = this.member.answers.filter(answer => answer.name == 'Date of birth')
                if(dob){
                  const date = moment(dob[0].answer);
            
                  const day = moment(date).format("D");
                  const month = moment(date).format("M");
                  const year = moment(date).format("Y");
            
                  this.form[id+"_day"].setValue(day);
                  this.form[id+"_month"].setValue(month);
                  this.form[id+"_year"].setValue(year);
                }
              }
              else {
                this.memberDetailsForm.addControl(`${id}_day`, new FormControl('', [Validators.min(1), Validators.max(31)]));
                this.memberDetailsForm.addControl(`${id}_month`, new FormControl('', [Validators.min(1), Validators.max(12)]));
                this.memberDetailsForm.addControl(`${id}_year`, new FormControl('', [Validators.min(1900), Validators.maxLength(4)]));

                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_day`]);
                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_month`]);
                this.dateQuestions.push(this.memberDetailsForm.controls[`${id}_year`]);
                this.dateQuestions.push(id);
                
              }
            } else {
              if (question.required) {
                this.memberDetailsForm.addControl(questionControl, new FormControl('', Validators.required));
              } else {
                this.memberDetailsForm.addControl(questionControl, new FormControl(''));
              }
            }
          }
        }
        this.populateQuestions();
      }, err => {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.spinner.hide();
      });
    });


  }


  // Once the form has loaded and the questions have been rerieved then populate
  // the form with the users customer questions
  populateQuestions() {
    for (const key in this.answers) {
      const ans = this.answers[key];
      if(this.form[key]) {
        this.form[key].setValue(ans.answer ? ans.answer : '');
      }
    }
    this.onQuestionChange();
  }

  // Handles conditional questions and updates the view accordingly
  onQuestionChange() {
    const customerQuestions = this.customerQuestions
    // Conditional questions logic
    for (let index = 0; index < customerQuestions.length; index++) {
      const q = customerQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty('conditional_question')) {
        // Get the conditional question based on the id set in q
        const conditionalQ = customerQuestions.filter(question => question.id == q.settings.conditional_question);
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(option => option.name == questionControl.value);

        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (conditionalQ.length > 0 && conditionalQ[0].visible && option.length > 0 && q.settings.conditional_answers.hasOwnProperty(option[0].id)) {
          this.form[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.form[q.id].setValue('');
          this.form[q.id].disable();
        }
      }
    }
  }

  // Cancel any changes made to the form data
  cancel() {
    this.setMemberDetails();
    this.populateQuestions();
  }

  // Set the values in the form using the members details from member object
  setMemberDetails() {
    // Set member attributes
    const fullMobile = `0${this.member.mobile}`
    this.form.first_name.setValue(this.member.first_name);
    this.form.last_name.setValue(this.member.last_name);
    this.form.mobile.setValue(fullMobile);
    this.form.address1.setValue(this.member.address1);
    this.form.address2.setValue(this.member.address2);
    this.form.address4.setValue(this.member.address4);
    this.form.country.setValue(this.member.country);
    this.form.postcode.setValue(this.member.postcode);
    const dob = this.member.answers.filter(answer => answer.name == 'Date of birth')
    if(dob){
      const date = moment(dob[0].answer);

      const day = moment(date).format("D");
      const month = moment(date).format("M");
      const year = moment(date).format("Y");

      // this.form["9_day"].setValue(day);
      // this.form.month.setValue(month);
      // this.form.year.setValue(year);
    }
  }

  // Submit the form update
  onSubmit() {

    // Chunk dates into array
    const dateQuestions = _.chunk(this.dateQuestions, 4);

    dateQuestions.forEach((dateChunks: any) => {
      const date = moment(`${dateChunks[0].value}-${dateChunks[1].value}-${dateChunks[2].value}`, "DD-MM-YYYY").toDate();

      // check if date is valid
      if (isNaN(date.getTime())) {
        this.form[`${dateChunks[3]}_day`].setErrors({ invalid: true })
        return;
      }

      // check if any date field is blank
      if (!(dateChunks[0].value && dateChunks[1].value && dateChunks[2].value)) {
        this.form[`${dateChunks[3]}_day`].setErrors({ required: true })
        return;
      }

      if (dateChunks[2].status === "INVALID") {
        this.form[`${dateChunks[3]}_day`].setErrors({ required: true })
        return;
      } else {
        this.form[`${dateChunks[3]}_day`].setErrors(null);
        return;
      }
    });

    this.submitted = true;
    // Create the questions object for the member update request
    this.memberDetailsForm.value['q'] = {};

    if (this.memberDetailsForm.invalid) {
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      // scroll to the top of the page if form is invalid
      this.scrollToTop();
      return;
    } else {
      this.spinner.show();

      // Create the q {} containing the updated answers
      for (const key in this.customerQuestions) {
        const question = this.customerQuestions[key];
        const questionObj = {
          answer: this.memberDetailsForm.value[question.id],
          name: question.name
        };
        this.memberDetailsForm.value['q'][question.id] = questionObj;
      }
      for (const control in this.form){
        if (this.form.hasOwnProperty(control)) {
          const element = this.form[control];
          let question = this.customerQuestions.filter(question => question.id.toString() === control);
          if(control.includes("_day")) {            
            question = this.customerQuestions.filter(question => question.id.toString() === control.split("_")[0]);
          }
          
          if (question.length > 0 && (element.value.length > 0 || element.value)) {
                  
            if(control.includes("_day")) {
              const day = this.memberDetailsForm.controls[control.split("_")[0]+"_day"].value;
              const month = this.memberDetailsForm.controls[control.split("_")[0]+"_month"].value;
              const year = this.memberDetailsForm.controls[control.split("_")[0]+"_year"].value;
              
              const date = moment(year +"-"+ month +"-"+ day).format();
              
              const q = {
                answer_id: question[0].id,
                answer: date
              }

              this.memberDetailsForm.value['q'][question[0].id] = q;
            }

          }

        }
      }
      // Update the member call
      this.memberService.updateMember(this.member, this.memberDetailsForm.value).then(member => {
        this.memberService.setMember(member);
        this.member = member;
        // Set the customer answers
        this.answers = this.member.q;
        this.alertService.success(this.translateService.instant('ACCOUNT.UPDATE_SUCCESS'));
        // scroll to the top of the page if form update is successful
        this.scrollToTop();
        this.spinner.hide();
        return;
      }).catch((error: HttpErrorResponse) => {
        this.alertService.error(this.translateService.instant('ACCOUNT.UPDATE_FAILED'))
        // scroll to the top of the page if form update is unsuccessful
        this.scrollToTop();
        this.spinner.hide();
      });
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  backToServices() {
    if(sessionStorage.getItem("bookingType")){
      const bookingType = JSON.parse(sessionStorage.getItem("bookingType"));
      if(bookingType == "block"){
        this.router.navigate(['/block-booking-confirmation']);
      }
    }
    this.router.navigate(['/confirmation']);
  }


  updateTab() { // keep checking to see if the calender is open and then make it tabable
    const markers: any = document.body.getElementsByClassName("mat-calendar-body-cell");
    if (markers.length !== 0) {
      for (let i in markers) {
        if (markers[i].tabIndex !== undefined && markers[i].classList.length !== 3) {
        markers[i].tabIndex = 0;
        markers[i].addEventListener("focus", function () { // these highlight it when we tab over it 
          this.style.backgroundColor = "yellow";  
        });
        markers[i].addEventListener("blur", function() {
          this.style.backgroundColor = "white";  
        });
        markers[i].addEventListener('keypress', function (e) { // this lets the enter key select a date
          if (e.key === 'Enter') {
            this.click();
          }
        });
        }
      }
    }
    this.openCalender()
  }

  openCalender() {
    setTimeout(() => {
      this.updateTab(),
        100
    });
  }
}
