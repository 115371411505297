import { Component, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver } from '@angular/core';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { AlertService } from 'src/app/_alert';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/jrni/api.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-mat-calendar',
  templateUrl: './mat-calendar.component.html',
  styleUrls: ['./mat-calendar.component.scss']
})
export class MatCalendarComponent implements OnInit {
  resourceSelection = AppConfig.settings.resourceSelection;
  minDate;
  datePickerMinDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  selectedSlot;
  availableDays: any;
  @Input() service: any;
  @Input() location: any;
  @Input() component: any;
  @Output() selectSlotUpdate = new EventEmitter<boolean>();
  slots: any[];
  slotsForRecurring: any[];
  slotsForRecurringAlt: any[] = [];
  quantity: number;
  uniqueSlots: any[];
  requiredResource: string;
  selectedDuration: any;
  selectedEndDate: any;
  hideDuration: boolean = false;
  prevSelectedAltDate: any = '';
  multiSlot: boolean = false;

  durationForm = new FormGroup({
    selectedDuration: new FormControl('')
  })
  facilityForm = new FormGroup({
    selectedFacility: new FormControl('')
  })

  startDate = "";
  endDate = new FormControl('');

  durations: any = [];
  totalPrice: any;

  requiredResourceTypes: any = {};
  uniqueSlotsForRecurrence: any[] = [];
  selectedFrequency = "";
  allowBlockBooking = false;
  blockBookingLimit: any;
  slotNotSelected = false;
  recurringDates = [];
  recurringBookings = [];
  reachedLimit = false;
  loadingFrequency = false;
  loadingFacility = false;
  startCalendar = false;
  alternateSlots: any = [];
  selectedAltDate = "";
  facilities = [];

  constructor(
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private servicesService: ServicesService,
    private apiService: ApiService,
    private departmentService: DepartmentService
  ) { }

  ngOnInit() {
    // Set the current date to today for the calendar to default to today
    this.currentDate = new Date();
    this.selectedDate = this.currentDate;
    // this.selectedEndDate = this.currentDate;
    this.loadingFacility = true;

    if (this.datastoreService.selectedDate) {
      this.selectedDate = this.datastoreService.selectedDate;
    }

    if (this.datastoreService.isAccounts) {
      this.hideDuration = true;
      this.selectedDuration = this.datastoreService.accountBooking.duration / 60;
    }

    if (this.servicesService.selectedService) {
      if (this.datastoreService.bookingType == 'block') {
        this.allowBlockBooking = true;
      }
      this.multiSlot = false;
      for (let question in this.service.extra) {
        if (question.endsWith('_quantity')) {
          let resourceType = question.split('_quantity')[0];
          // Get the question which contains the start time offset in minutes
          let resourceOffset = this.service.extra[resourceType + "_start_offset"];

          try {
            let quantity = parseInt(this.service.extra[question]);
            this.quantity = quantity; // remove this when making multiple resource bookings
            if (quantity > 0) {
              if (quantity > 1) { // hide the “Facility (Pitch/Area)” filter for any service that books/blocks out multiple resources
                this.multiSlot = true;
              }
              this.requiredResourceTypes[resourceType] = {
                quantity: quantity,
                // If a start time offset is provided, set it
                startOffset: resourceOffset ? parseInt(resourceOffset) : 0
              };
            }

          } catch (err) { }
        }
      }
      this.datastoreService.totalPrice = this.servicesService.selectedService.prices[0];
    }

    // Set max date as yesterday so the user cannot select a date until
    this.minDate = new Date(this.service.min_advance_datetime);
    this.maxDate = new Date(this.service.max_advance_datetime);

    this.calendarRun();
  }

  async onChangeFacility() {
    this.datastoreService.selectedResource = this.facilityForm.controls.selectedFacility.value != "" ? parseInt(this.facilityForm.controls.selectedFacility.value) : "";
    localStorage.setItem('selectedResource', JSON.stringify(this.datastoreService.selectedResource));
    this.updateSlots(this.selectedDate, this.durationForm.controls.selectedDuration.value);
  }

  async setFacility() {

    this.datastoreService.resources = [];

    this.facilities = [];

    const date = new Date();
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];

    // setup our request params
    const params = {
      'service_id': this.service.id,
      'date': isoTime,
      // 'end_date': isoTime,
      'duration': this.durationForm.controls.selectedDuration.value
    };

    await this.availabilityService.getSlotsTimeData(this.location, params).then(res => {
      this.slots = [];
      res['_embedded'].events.forEach(staff => {
        this.datastoreService.resources.push(staff);
        this.facilities.push({ "value": staff.name, "id": staff.resource_id })
      });
    });

    localStorage.setItem('resources', JSON.stringify(this.datastoreService.resources));

    if (this.datastoreService.selectedResource) {
      this.facilityForm.controls.selectedFacility.setValue(this.datastoreService.selectedResource);
    }

    this.loadingFacility = false;

  }

  async calendarRun() {
    let serviceAvailability;
    // Get the available times for a service
    if (this.component) {
      serviceAvailability = await this.availabilityService.requestAvailableDays(this.location, this.service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    } else {
      serviceAvailability = await this.availabilityService.requestAvailableDaysService(this.location, this.service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    }
    // Set the available times so we do have to keep sending the same request
    await this.availabilityService.setAvailableDays(serviceAvailability.days);

    // If service availability contains data then filter the days that are available
    if (serviceAvailability.days && serviceAvailability.days.length > 0) {
      // Get the available days
      this.availableDays = serviceAvailability.days.filter(day => day.spaces > 0);
    } else {
      this.alertService.error(this.translateService.instant('COMMON.NO_AVAILABILITY'));
    }

    this.durationForm.controls.selectedDuration.setValue(this.service.durations[0]);
    this.datastoreService.selectedDuration = this.durationForm.controls.selectedDuration.value;

    this.service.durations.forEach((duration: any, index: any) => {
      const durationText = this.minutes_to_hhmm(duration);
      let price = `${this.service.prices[index] / 100}`;
      this.durations.push({ key: duration, value: `${durationText} - £${new Number(parseFloat(price)).toFixed(2)}` });
    });

    const defaultDuration = this.service.durations[0];
    if (this.selectedDuration == null) {
      this.selectedDuration = defaultDuration;
    }

    let startDate: any;
    // If the selected date has been store in datastore then pre populate the selection
    if (this.datastoreService.selectedDate) {
      startDate = this.datastoreService.selectedDate;
      this.updateSlots(new Date(startDate), this.selectedDuration);

      this.selectedSlot = await this.availabilityService.getSelectedSlot();
      this.selectedSlot.durations = this.selectedDuration;
    } else {
      if (this.availableDays && this.availableDays.length > 0) {
        startDate = this.availableDays[0].date;
        // Store the selected date
        this.datastoreService.selectedDate = this.availableDays[0].date;
        this.updateSlots(new Date(this.availableDays[0].date), this.selectedDuration);
      } else {
        // Store the selected date
        this.datastoreService.selectedDate = new Date();
        this.updateSlots(new Date(), this.selectedDuration);
        startDate = new Date();
      }
    }
    this.spinner.hide();

    this.setFacility();

    setTimeout(() => {
      this.updateTab(),
        100
    });

  }

  async setStartEndCalendar() {
    this.loadingFrequency = true;
    const company = this.departmentService.selectedDepartment;
    // get booking limit for company
    this.blockBookingLimit = await this.apiService.getUserDefinedFieldValue(company, AppConfig.settings.core.company_id, 'block_booking_limit');
    this.startCalendar = true;
    this.selectedEndDate = "";
    this.loadingFrequency = false;
  }

  async findAvailableTimes(date: any, recurringDate: any, prevSelectedAltDate, selectBox) {

    if (date == "") {
      recurringDate.availableTimes = [];
      return;
    }
    if (prevSelectedAltDate) {
      const found = this.datastoreService.recurringBookingSlots.filter(slot =>
        moment(slot.datetime).format('YYYY-MM-DD') != moment(prevSelectedAltDate).format('YYYY-MM-DD'));

      if (found.length > 0) {
        this.datastoreService.recurringBookingSlots = found;
      }
    }

    recurringDate.timeRequired = true;

    this.slotsForRecurringAlt = [];

    date = new Date(date);
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];

    // setup our request params
    const params = {
      'service_id': this.service.id,
      'date': isoTime,
      // 'end_date': isoTime,
      'duration': this.durationForm.controls.selectedDuration.value
    };

    // if its not a serivce with multi resource booking
    if (Object.keys(this.requiredResourceTypes).length == 0) {
      // Only add slots with standard availability
      const availability = await this.availabilityService.getSlotsTimeData(this.location, params);
      if (availability) {
        let selectedResource = this.datastoreService.selectedResource;

        let slotsForRecurring = [];
        for (const staff of availability['_embedded'].events) {
          for (const timeSlot of staff.times) {
            timeSlot.person_id = staff.person_id;
            if (staff.resource_id === selectedResource || selectedResource === "" || selectedResource === undefined) {
              if (timeSlot.avail == 1) {
                slotsForRecurring.push(timeSlot);
              }
            }
          };
        };

        // Remove dupes from differant staff
        const uniqueSlotsForRecurrence = slotsForRecurring.filter((slot, index, self) =>
          index === self.findIndex((t) => (
            t.datetime === slot.datetime
          ))
        )

        // Sort by time order
        recurringDate.availableTimes = uniqueSlotsForRecurrence.sort((a, b) => a.time - b.time);
      };
    } else {

      // if its a service with multi resource booking

      // Group resources by type
      let groupedResources = await this.getGroupedResources(this.location, this.requiredResourceTypes);
      // loop over resources and grab the availability for each one
      let slotPromise = await new Promise(async resolve => {
        let resorucesProcessed = 0;
        for (const requiredResource of Object.keys(this.requiredResourceTypes)) {
          if (requiredResource !== undefined) {
            for (const resource of groupedResources[requiredResource]) {
              // add the resource to the request
              params["resource_ids"] = [resource.id];
              await this.getSlotsForRecurringAlt(params, resource.extra.resource_type);
              resorucesProcessed++;
              if (resorucesProcessed == groupedResources[requiredResource].length) {
                resolve(this.slotsForRecurringAlt);
              }
            }
          }
        };
      });

      const tempSlots: any = await slotPromise;

      // we have every slot need to process them
      // Get around some weird "never" property bug
      const slots = [];
      for (const slot of tempSlots) {
        slots.push(slot)
      };

      // find all the associated slots and put them together
      let uniqueSlots = [];
      for (const slot of slots) {
        if (this.containsObject(slot, uniqueSlots)) {
          let slotArray = slots.find(slotArr => slotArr.datetime == slot.datetime);
          if (!slotArray.associatedSlots) {
            slotArray.associatedSlots = [];
            slotArray.associatedSlots.push(slotArray);
          }
          slotArray.associatedSlots.push(slot);
        } else {
          uniqueSlots.push(slot);
        }
      };

      uniqueSlots = uniqueSlots.filter(slot => moment(slot.datetime).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD'));

      // remove any slots that are already in the recurring dates array
      let filteredSlots = [];
      for (const recurringDate of this.datastoreService.recurringDates) {
        if (recurringDate.available) {
          const slots = uniqueSlots.filter(slot => moment(slot.datetime).format() != moment(recurringDate.date).format());
          if (slots.length > 0) {
            filteredSlots.push(slots);
          }
        }
      }

      if (filteredSlots.length > 0) {
        uniqueSlots = filteredSlots[filteredSlots.length - 1];
      }

      // remove all the slots that dont have our quanitity required
      uniqueSlots = uniqueSlots.map(slots => {
        if (slots.associatedSlots) {
          if ((slots.associatedSlots.length) >= this.quantity) {
            return slots;
          }
        }
      });

      // remove slots that dont have enough space
      uniqueSlots = uniqueSlots.filter(function (defined) {
        return defined != null;
      });

      // Sort by time order
      uniqueSlots.sort((a, b) => a.time - b.time)

      // Give it to the front end
      recurringDate.availableTimes = uniqueSlots;
    }
  }

  updateRecurringSlots($event, date) {

    // get the slot value of the selected time
    const selectedSlot = $event.target.value;

    if (selectedSlot == "") {
      date.timeSelected = false;
      const found = this.datastoreService.recurringBookingSlots.filter(slot => moment(slot.datetime).format('YYYY-MM-DD') != moment(date.name).format('YYYY-MM-DD'));

      if (found.length > 0) {
        this.datastoreService.recurringBookingSlots = found;
        let updatedRecurringSlots = [];
        const recurringDates = this.datastoreService.recurringBookingSlots;
        const duration = this.durationForm.controls.selectedDuration.value / 60 + ' hours(s)';

        for (let recurringDate of recurringDates) {
          updatedRecurringSlots.push({ 'date': recurringDate.datetime, 'formatted': moment(recurringDate.datetime).format('DD MMM YYYY, HH:mm') + ", " + duration, 'available': recurringDate.avail == 1 ? true : false });
        }

        //sort slots by date
        this.datastoreService.recurringDates = updatedRecurringSlots.filter(date => date.available === true);
        localStorage.setItem("recurringDates", JSON.stringify(this.datastoreService.recurringDates));

        let durationIndex = this.servicesService.selectedService.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
        this.datastoreService.totalPrice = this.servicesService.selectedService.prices[durationIndex] * this.datastoreService.recurringBookingSlots.length;
        localStorage.setItem("totalPrice", JSON.stringify(this.datastoreService.totalPrice));
      }
      return;
    } else {
      date.timeSelected = true;
    }

    const duration = this.durationForm.controls.selectedDuration.value / 60 + ' hours(s)';

    // find the slot of the selected time
    const slot = date.availableTimes.filter(slot => slot.datetime == selectedSlot)[0];
    /// update the orginal slot information so we can display them as updated
    date.date = slot.datetime;
    date.formatted = moment(slot.datetime).format('DD MMM YYYY, HH:mm') + ", " + duration;

    // check if a slot already exists in the array for this date
    const slotExists = this.datastoreService.recurringBookingSlots.filter(slot => moment(slot.datetime).format('YYYY-MM-DD') == moment(selectedSlot).format('YYYY-MM-DD'))[0];

    // if the slot exists then exclude it from the array so we can replace it
    if (slotExists) {
      this.datastoreService.recurringBookingSlots = this.datastoreService.recurringBookingSlots.filter(slot => moment(slot.datetime).format('YYYY-MM-DD') != moment(selectedSlot).format('YYYY-MM-DD'));
    }

    // for multi resource servivce - update the quanity for alternative slots that are selected 
    // as this is used to block out other resources
    if (slot.associatedSlots && slot.associatedSlots.length) {
      slot.quantity = slot.associatedSlots.length;
    }

    this.datastoreService.recurringBookingSlots.push(slot);

    let updatedRecurringSlots = [];
    const recurringDates = this.datastoreService.recurringBookingSlots;

    for (let recurringDate of recurringDates) {
      updatedRecurringSlots.push({ 'date': recurringDate.datetime, 'formatted': moment(recurringDate.datetime).format('DD MMM YYYY, HH:mm') + ", " + duration, 'available': recurringDate.avail == 1 ? true : false });
    }

    //sort slots by date
    this.datastoreService.recurringDates = updatedRecurringSlots.filter(date => date.available === true);
    localStorage.setItem("recurringDates", JSON.stringify(this.datastoreService.recurringDates));

    let durationIndex = this.servicesService.selectedService.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
    this.datastoreService.totalPrice = this.servicesService.selectedService.prices[durationIndex] * this.datastoreService.recurringBookingSlots.length;
    localStorage.setItem("totalPrice", JSON.stringify(this.datastoreService.totalPrice));

  }

  setEndDate(date) {
    const endDate = moment(new Date(date));
    this.endDate.setValue(endDate.format('DD-MM-YYYY'));
    const startDate = moment(new Date(this.startDate));
    if (endDate.isAfter(startDate)) {
      this.selectedEndDate = endDate;
      this.startCalendar = false;
      this.frequency();
    }
  }

  updateTab() {
    const markers: any = document.body.getElementsByClassName("mat-calendar-body-cell");
    for (let i in markers) {
      if (markers[i].tabIndex !== undefined && markers[i].classList.length !== 3) {
        markers[i].tabIndex = 0;
        markers[i].addEventListener("focus", function () { // these highlight it when we tab over it 
          this.style.backgroundColor = "yellow";
        });
        markers[i].addEventListener("blur", function () {
          this.style.backgroundColor = "white";
        });
        markers[i].addEventListener('keypress', function (e) { // this lets the enter key select a date
          if (e.key === 'Enter') {
            this.click();
          }
        });
      }
    }
  }

  durationSelected() {
    this.spinner.show();
    // this.updateSlotsForDuration(this.selectedDate, this.durationForm.controls.selectedDuration.value);
    this.datastoreService.selectedDuration = this.durationForm.controls.selectedDuration.value;

    if (!this.servicesService.selectedService) {
      // selectedService isn't set, accounts stuff
      this.servicesService.set(this.service);
    }

    let durationIndex = this.servicesService.selectedService.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
    this.datastoreService.totalPrice = this.servicesService.selectedService.prices[durationIndex];

    this.updateSlots(this.selectedDate, this.durationForm.controls.selectedDuration.value);
    if (this.selectedFrequency) {
      this.frequency();
    }
  }

  // Check if the date is unavailable and if so return false
  unavailableDates = (date: Date): boolean => {
    const momentDate = moment(date);
    if (this.availableDays) {
      return this.availableDays.some(day => momentDate.isSame(day.date));
    } else {
      return false;
    }
  }

  // Date selected on calendar
  // THIS ONE IS USED FOR BASE CALENDER
  async updateSlots(date, duration) {

    if (!moment(date).isValid()) {
      date = this.datastoreService.selectedDate ? this.datastoreService.selectedDate : new Date();
    } else {
      this.datastoreService.selectedDate = date;
    }

    this.startDate = date;
    this.recurringDates = [];
    this.recurringBookings = [];
    this.datastoreService.recurringBookingSlots = [];
    this.datastoreService.recurringDates = [];
    this.datePickerMinDate = new Date(date);
    this.datePickerMinDate.setDate(this.datePickerMinDate.getDate() + 1);

    this.selectedSlot = null;

    // add two hours onto the date so when we convert with iso it always keeps the same date
    date.setHours(date.getHours() + 2);

    this.spinner.show();
    // Clear slots array, Its about to get wild
    this.slots = [];

    //// Before we get the slots for the service alone, We are going to check if the service requires extra resources to book. \\\\
    // Retrieve the required resource from the service business questions
    // let requiredResourceTypes = {};
    // for (let question in this.service.extra) {
    //   if (question.endsWith('_quantity')) {
    //     let resourceType = question.split('_quantity')[0];
    //     // Get the question which contains the start time offset in minutes
    //     let resourceOffset = this.service.extra[resourceType + "_start_offset"];

    //     try {
    //       let quantity = parseInt(this.service.extra[question]);
    //       this.quantity = quantity; // remove this when making multiple resource bookings
    //       if (quantity > 0) {
    //         requiredResourceTypes[resourceType] = {
    //           quantity: quantity,
    //           // If a start time offset is provided, set it
    //           startOffset: resourceOffset ? parseInt(resourceOffset) : 0
    //         };
    //       }

    //     } catch (err) { }
    //   }
    // }

    // Update the selected date
    this.selectedDate = date;

    // Store the selected date
    this.datastoreService.selectedDate = this.selectedDate;

    // slice time off
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];

    // setup our request params
    const params = {
      'service_id': this.service.id,
      'date': isoTime,
      // 'end_date': isoTime,
      'duration': duration
    };
    if (Object.keys(this.requiredResourceTypes).length == 0) {
      // Only add slots with standard availability
      await this.availabilityService.getSlotsTimeData(this.location, params).then(res => {
        this.slots = [];
        if (this.facilityForm.controls.selectedFacility.value) {
          res['_embedded'].events.forEach(staff => {
            if (staff.resource_id == this.facilityForm.controls.selectedFacility.value) {
              staff.times.forEach(timeSlot => {
                timeSlot.person_id = staff.person_id;
                if (timeSlot.avail == 1) {
                  this.slots.push(timeSlot);
                }
              });
            }
          });
        } else {
          res['_embedded'].events.forEach(staff => {
            staff.times.forEach(timeSlot => {
              timeSlot.person_id = staff.person_id;
              if (timeSlot.avail == 1) {
                this.slots.push(timeSlot);
              }
            });
          });
        }

        // Remove dupes from differant staff
        this.uniqueSlots = this.slots.filter((slot, index, self) =>
          index === self.findIndex((t) => (
            t.datetime === slot.datetime
          ))
        )
        // Sort by time order
        this.uniqueSlots.sort((a, b) => a.time - b.time)
        this.spinner.hide();
      });
    } else {
      // Get slots with extra time values for our resources
      // Group resources by type
      let groupedResources = await this.getGroupedResources(this.location, this.requiredResourceTypes);
      let selectedResource = this.datastoreService.selectedResource;
      // Get the availability without any resource params, This is our base availability
      let primaryAvailability = await this.getSlots(params, null);
      let primaryAvailabilityByDate = {};
      let dateString = primaryAvailability[0].date ? primaryAvailability[0].date.toISODate() : primaryAvailability[0].datetime;
      primaryAvailabilityByDate[dateString] = primaryAvailability;

      // loop over resources and grab the availability for each one
      let slotPromise = new Promise(resolve => {
        let resorucesProcessed = 0;
        Object.keys(this.requiredResourceTypes).forEach(requiredResource => {
          this.requiredResource = requiredResource;
          if (requiredResource !== undefined) {
            groupedResources[requiredResource].forEach((resource, index) => {
              // add the resource to the request
              params["resource_ids"] = [resource.id];
              this.getSlots(params, resource.extra.resource_type);
              resorucesProcessed++;
              if (resorucesProcessed == groupedResources[requiredResource].length) {
                resolve(this.slots);
              }
            })
          }
        });
      });

      slotPromise.then((tempSlots: any) => {
        if (selectedResource !== "") {
          tempSlots = tempSlots.filter((x: any) => x.resource_id === selectedResource)
        }
        // we have every slot need to process them
        // Get around some weird "never" property bug
        const slots = [];
        tempSlots.forEach(slot => {
          slots.push(slot)
        });

        // find all the associated slots and put them together
        let uniqueSlots = [];
        slots.forEach(slot => {
          if (this.containsObject(slot, uniqueSlots)) {
            let slotArray = slots.find(slotArr => slotArr.datetime == slot.datetime);
            if (!slotArray.associatedSlots) {
              slotArray.associatedSlots = [];
              slotArray.associatedSlots.push(slotArray);
            }
            slotArray.associatedSlots.push(slot);
          } else {
            slot.associatedSlots = [slot]
            uniqueSlots.push(slot);
          }
        });

        // remove all the slots that dont have our quanitity required
        uniqueSlots = uniqueSlots.map(slots => {
          if (slots.associatedSlots) {
            if ((slots.associatedSlots.length) >= this.quantity) {
              return slots;
            }
          }
        })

        // remove slots that dont have enough space
        uniqueSlots = uniqueSlots.filter(function (defined) {
          return defined != null;
        });

        // Sort by time order
        uniqueSlots.sort((a, b) => a.time - b.time)
        // Give it to the front end
        this.uniqueSlots = uniqueSlots;
        this.spinner.hide();
      })
    }
  }

  containsObject(obj, list) {
    return list.some(elem => elem.datetime === obj.datetime)
  }

  async getSlots(params, type) {
    let slotsStored = 0;
    return await new Promise(resolve => {
      this.availabilityService.getSlotsTimeData(this.location, params).then(res => {
        for (const resource of res['_embedded'].events) {
          for (const timeSlot of resource.times) {
            timeSlot.person_id = resource.person_id;
            timeSlot.resource_id = resource.resource_id;
            timeSlot.resource_type = type;
            if (timeSlot.avail == 1) {
              this.slots.push(timeSlot);
            }
            slotsStored++;
            if (slotsStored === resource.times.length) {
              resolve(this.slots);
            }
          };
        };
      });
    });
  }

  async getSlotsForRecurring(params, type) {
    let slotsStored = 0;
    return await new Promise(resolve => {
      this.availabilityService.getSlotsTimeData(this.location, params).then(res => {
        for (const resource of res['_embedded'].events) {
          for (const timeSlot of resource.times) {
            timeSlot.person_id = resource.person_id;
            timeSlot.resource_id = resource.resource_id;
            timeSlot.resource_type = type;
            if (timeSlot.avail == 1) {
              this.slotsForRecurring.push(timeSlot);
            }
            slotsStored++;
            if (slotsStored === resource.times.length) {
              resolve(this.slotsForRecurring);
            }
          };
        };
      });
    });
  }
  async getSlotsForRecurringAlt(params, type) {
    let slotsStored = 0;
    return await new Promise(resolve => {
      this.availabilityService.getSlotsTimeData(this.location, params).then(res => {
        for (const resource of res['_embedded'].events) {
          for (const timeSlot of resource.times) {
            timeSlot.person_id = resource.person_id;
            timeSlot.resource_id = resource.resource_id;
            timeSlot.resource_type = type;
            if (timeSlot.avail == 1) {
              this.slotsForRecurringAlt.push(timeSlot);
            }
            slotsStored++;
            if (slotsStored === resource.times.length) {
              resolve(this.slotsForRecurringAlt);
            }
          };
        };
      });
    });
  }

  getGroupedResources(company, requiredResourceTypes) {
    return new Promise(resolve => {
      // Get all resources
      company.$getResources().then(resources => {
        // Filter out resources we don't care about
        resources = resources.filter(resource => {
          return requiredResourceTypes[resource.extra.resource_type];
        });

        // group resources by type
        var groupedResources = _.groupBy(resources, item => {
          return item.extra.resource_type;
        });

        // Check whether we have enough resources
        for (let requiredResourceType in requiredResourceTypes) {
          var requiredCount = requiredResourceTypes[requiredResourceType].quantity;
          // Check whether we have the expected number of resources of this type
          if (!groupedResources[requiredResourceType] || requiredCount > groupedResources[requiredResourceType].length) {
            // We don't have enough resources!
            var found = groupedResources[requiredResourceType] ? groupedResources[requiredResourceType].length : 0;
            resolve([]);
          }
        }
        resolve(groupedResources);
      });
    });
  }

  // Set the slot
  selectSlot(slot) {
    this.slotNotSelected = false;
    slot.quantity = this.quantity;
    this.selectedSlot = slot;
    this.selectedSlot.durations = this.selectedDuration;
    this.availabilityService.setSelectedSlot(slot);
    this.selectSlotUpdate.emit(true);

    if (this.selectedFrequency) {
      this.frequency();
    }
  }

  minutes_to_hhmm(numberOfMinutes) {
    //create duration object from moment.duration  
    var duration = moment.duration(numberOfMinutes, 'minutes');

    //calculate hours
    var hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());

    //get minutes
    var mm = duration.minutes();

    let hour = "";

    if (hh > 1) {
      hour = hh + " hours";
    } else if (hh == 1) {
      hour = hh + " hour"
    }

    let minute = "";

    if (mm > 0) {
      minute = mm + " minutes";
    }

    if (hour === "") {
      return minute;
    }

    return hour + ' ' + minute;
  }

  async updateSlotsForDuration(date, duration) {
    this.slots = [];

    const params = {
      'service_id': this.service.id,
      'start_date': moment(date).format('YYYY-MM-DD'),
      'end_date': moment(date).format('YYYY-MM-DD'),
      'duration': duration
    };

    // Only add slots with availability
    const slots = await this.availabilityService.getSlots(this.location, params);

    if (slots) {
      this.slots = slots.filter(slot => slot.available === true);
    };

    this.spinner.hide();

    return this.slots;
  }

  setFrequency(selectedFrequency) {
    this.selectedFrequency = selectedFrequency;
    if (this.selectedEndDate) {
      this.frequency();
    }
  }

  async frequency() {
    const frequency: any = this.selectedFrequency;
    this.recurringDates = [];
    this.recurringBookings = [];
    if (!this.selectedSlot) {
      this.slotNotSelected = true;
      return;
    } else if (!this.selectedFrequency || !this.selectedEndDate) {
      return;
    } else {
      this.slotNotSelected = false;
    }

    this.loadingFrequency = true;

    const duration = this.durationForm.controls.selectedDuration.value / 60 + ' hours(s)';
    const slot = this.selectedSlot;
    const firstBooking = this.selectedSlot.datetime;

    const dateTimeDuration = moment(firstBooking).format('DD MMM YYYY, HH:mm') + ", " + duration;

    // arrays to display in front end
    this.recurringDates.push(dateTimeDuration);

    // array to send to backend
    this.recurringBookings.push(slot.datetime);

    let addedDate = moment(firstBooking);
    let bookingNo = 1;

    let nextSlot = this.selectedSlot;
    // while addedDate is before the end date add the next booking

    //copy without reference
    let endDate = moment(this.selectedEndDate);
    endDate.add(1, 'days');

    while (addedDate.isBefore(endDate)) {
      if (this.selectedFrequency === 'fortnightly') {
        bookingNo++;
        addedDate = addedDate.add(2, 'weeks');
      } else {
        bookingNo++;
        addedDate = addedDate.add(1, frequency);
      }

      // if the next slot is is after the end date then stop
      if (addedDate.isAfter(endDate)) {
        await this.findAvailableSlots(duration);
        return;
      }

      if (bookingNo > this.blockBookingLimit) {
        await this.findAvailableSlots(duration);
        this.reachedLimit = true;
        return;
      } else {
        this.reachedLimit = false;
      }

      const thisDate = this.availableDays.filter(d =>
        moment(d.date).format('YYYY-MM-DD') == addedDate.format('YYYY-MM-DD')
      );

      if (thisDate.length > 0 && thisDate[0].spaces > 0) {
        this.recurringBookings.push(addedDate.format());
      }
      // this.recurringDates.push(addedDate.format('DD MMM YYYY, HH:mm') + ", " + duration);
    }

  }

  async findAvailableSlots(duration) {
    const startSlot = Object.assign({}, this.selectedSlot);
    const slots = [];
    for (const [index, time] of this.recurringBookings.entries()) {
      // && day.resource_id === selectedResource || selectedResource === "" || selectedResource === undefined
      await this.findSlots(time, 60);
      if (this.uniqueSlotsForRecurrence.length > 0) {
        const availability = this.uniqueSlotsForRecurrence.filter(slot => slot.datetime === time);
        if (availability.length > 0) {
          this.recurringDates.push({ 'id': index, 'timeRequired': false, 'date': availability[0].datetime, 'formatted': moment(availability[0].datetime).format('DD MMM YYYY, HH:mm') + ", " + duration, 'available': availability[0].avail == 1 ? true : false });
          availability[0].quantity = this.quantity;
          slots.push(availability[0]);
        } else {
          let endDate = moment(this.selectedEndDate);
          endDate.add(1, 'days');
          // get available slots after the last recurring date and before the selected end date
          let selectedResource = this.datastoreService.selectedResource;
          const availableDays = this.availableDays.filter(day =>
            moment(day.date).isAfter(moment(this.recurringDates[this.recurringDates.length - 1].date)) &&
            moment(day.date).isBefore(endDate));

          this.recurringDates.push({ 'date': time, 'formatted': moment(time).format('DD MMM YYYY, HH:mm') + ", " + duration, 'available': false, availableDays: availableDays, availableTimes: [] });
        }
      }
      // slots.push(startSlot);
    };

    //store available slots in datastore service
    this.datastoreService.recurringBookingSlots = slots;
    //sort slots by date
    this.datastoreService.recurringDates = this.recurringDates.filter(date => date.available === true);
    localStorage.setItem("recurringDates", JSON.stringify(this.datastoreService.recurringDates));

    let durationIndex = this.servicesService.selectedService.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
    this.datastoreService.totalPrice = this.servicesService.selectedService.prices[durationIndex] * slots.length;
    localStorage.setItem("totalPrice", JSON.stringify(this.datastoreService.totalPrice));

    //end loading
    this.loadingFrequency = false;
    return;

  }

  async findSlots(date, duration) {

    this.uniqueSlotsForRecurrence = [];

    date = new Date(date);

    // add two hours onto the date so when we convert with iso it always keeps the same date
    date.setHours(date.getHours() + 2);

    // Clear slots array, Its about to get wild
    this.slotsForRecurring = [];

    // slice time off
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];

    // setup our request params
    const params = {
      'service_id': this.service.id,
      'date': isoTime,
      // 'end_date': isoTime,
      'duration': duration
    };

    if (Object.keys(this.requiredResourceTypes).length == 0) {
      // Only add slots with standard availability
      const availability = await this.availabilityService.getSlotsTimeData(this.location, params);
      let selectedResource = this.datastoreService.selectedResource;

      if (availability) {
        this.slotsForRecurring = [];
        for (const staff of availability['_embedded'].events) {
          if (staff.resource_id === selectedResource || selectedResource === "" || selectedResource === undefined) {
            for (const timeSlot of staff.times) {
              timeSlot.person_id = staff.person_id;
              if (timeSlot.avail == 1) {
                this.slotsForRecurring.push(timeSlot);
              }
            };
          }
        };
        // Remove dupes from differant staff
        this.uniqueSlotsForRecurrence = this.slotsForRecurring.filter((slot, index, self) =>
          index === self.findIndex((t) => (
            t.datetime === slot.datetime
          ))
        )

        // Sort by time order
        this.uniqueSlotsForRecurrence.sort((a, b) => a.time - b.time);
        this.spinner.hide();
      };
    } else {
      // Get slots with extra time values for our resources
      // Group resources by type
      let groupedResources = await this.getGroupedResources(this.location, this.requiredResourceTypes);

      // Get the availability without any resource params, This is our base availability
      let primaryAvailability = await this.getSlotsForRecurring(params, null);
      let primaryAvailabilityByDate = {};
      let dateString = primaryAvailability[0].date ? primaryAvailability[0].date.toISODate() : primaryAvailability[0].datetime;
      primaryAvailabilityByDate[dateString] = primaryAvailability;

      // loop over resources and grab the availability for each one
      let slotPromise = await new Promise(async resolve => {
        this.slotsForRecurring = [];
        let resorucesProcessed = 0;
        for (const requiredResource of Object.keys(this.requiredResourceTypes)) {
          this.requiredResource = requiredResource;
          if (requiredResource !== undefined) {
            for (const resource of groupedResources[requiredResource]) {
              // add the resource to the request
              params["resource_ids"] = [resource.id];
              await this.getSlotsForRecurring(params, resource.extra.resource_type);
              resorucesProcessed++;
              if (resorucesProcessed == groupedResources[requiredResource].length) {
                resolve(this.slotsForRecurring);
              }
            }
          }
        };
      });

      const tempSlots: any = await slotPromise;

      // we have every slot need to process them
      // Get around some weird "never" property bug
      const slots = [];
      for (const slot of tempSlots) {
        slots.push(slot)
      };

      // find all the associated slots and put them together
      let uniqueSlots = [];
      for (const slot of slots) {
        if (this.containsObject(slot, uniqueSlots)) {
          let slotArray = slots.find(slotArr => slotArr.datetime == slot.datetime);
          if (!slotArray.associatedSlots) {
            slotArray.associatedSlots = [];
            slotArray.associatedSlots.push(slotArray);
          }
          slotArray.associatedSlots.push(slot);
        } else {
          uniqueSlots.push(slot);
        }
      };

      // remove all the slots that dont have our quanitity required
      uniqueSlots = uniqueSlots.map(slots => {
        if (slots.associatedSlots) {
          if ((slots.associatedSlots.length) >= this.quantity) {
            return slots;
          }
        }
      })

      // remove slots that dont have enough space
      uniqueSlots = uniqueSlots.filter(function (defined) {
        return defined != null;
      });

      // Sort by time order
      uniqueSlots.sort((a, b) => a.time - b.time)
      // Give it to the front end
      this.uniqueSlotsForRecurrence = uniqueSlots;

    }
  }

  removeSlot(slot) {
    this.recurringDates = this.recurringDates.filter(s => s.date !== slot.date);
    this.datastoreService.recurringDates = this.datastoreService.recurringDates.filter(s => s.date !== slot.date);
    localStorage.setItem("recurringDates", JSON.stringify(this.datastoreService.recurringDates));

    this.datastoreService.recurringBookingSlots = this.datastoreService.recurringBookingSlots.filter(s => s.datetime !== slot.date);

    let durationIndex = this.servicesService.selectedService.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
    this.datastoreService.totalPrice = this.servicesService.selectedService.prices[durationIndex] * this.datastoreService.recurringBookingSlots.length;
    localStorage.setItem("totalPrice", JSON.stringify(this.datastoreService.totalPrice));
  }
}
