import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { CapitaService } from 'src/app/services/jrni/capita.service';
import { ApiService } from 'src/app/services/jrni/api.service';
import { BookingService } from 'src/app/services/jrni/booking.service';
import * as moment from 'moment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  resourceSelection = AppConfig.settings.resourceSelection;
  selectedDepartment;
  selectedLocation;
  selectedService;
  selectedResource;
  pageTitle: string;
  summaryPageText: string;
  selectedSlot;
  selectedDuration;
  termsAndConditions;
  tncForm: FormGroup;
  privacyStatementForm: FormGroup;
  submitted = false;
  privacyUrl: string;
  tncsUrl: string;
  items;
  bookingQuestions = [];
  bookingAnswers = [];
  displayTnCs: boolean;
  price;
  originalPrice;
  private redirectUrl = AppConfig.settings.payment.redirect_url;
  private backUrl = AppConfig.settings.payment.back_url;
  bookingRes: any;
  client: any;
  isBlockBooking = false;
  recurringDates = [];
  optOutFeature = AppConfig.settings.optOutVatExemption;
  optOutVisbile = false;
  vatOptOut = false;
  availabilityWarning = false;
  coupon: any = false;
  couponApplied: boolean = false;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private basketService: BasketService,
    private bookingService: BookingService,
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private capitaService: CapitaService,
    private apiService: ApiService
  ) { }

  // Getter for easy access to form fields
  get form() { return this.tncForm.controls; }

  // Getter for easy access to form fields
  get privacyForm() { return this.privacyStatementForm.controls; }


  ngOnInit() {

    this.scrollToTop();
    this.availabilityWarning = false;

    this.selectedDuration = this.datastoreService.selectedDuration;
    // Set the questions and answer
    this.bookingQuestions = this.datastoreService.bookingQuestions.filter(question => !question.admin_only);

    // Get the booking answers and add them to an array ready to add to basket
    for (var key in this.datastoreService.bookingForm.value) {
      // this is to remove any checkboxes that are uncheck or "false" due to jrni still considering them as paid
      if (this.datastoreService.bookingForm.value[key] != false) {
        {
          this.bookingAnswers.push({
            id: key,
            answer: this.datastoreService.bookingForm.value[key],
          });
        }
      }
    }
    this.datastoreService.bookingAnswers = this.bookingAnswers;

    if (this.datastoreService.bookingType === 'block') {
      this.isBlockBooking = true;
      this.recurringDates = this.datastoreService.recurringDates;
    }

    this.selectedResource = this.datastoreService.selectedResource;

    if (this.selectedResource) {
      this.selectedResource = this.datastoreService.resources.filter(res => res.resource_id == this.selectedResource)[0];
    }

    // this.client = this.datastoreService.customerDetailsForm.value;

    // Initialise the terms and conditions form
    this.tncForm = this.formBuilder.group({
      tncCB: [false, Validators.requiredTrue]
    });

    // Initialise the privacy statement form
    this.privacyStatementForm = this.formBuilder.group({
      privacyStatement: [false, Validators.requiredTrue]
    });

    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('summary_page_title') ? this.pageTitle = this.selectedDepartment.extra.summary_page_title : this.pageTitle = "Summary title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('summary_page_text') ? this.summaryPageText = this.selectedDepartment.extra.summary_page_text : null;
          // Tncs
          this.getTermsAndConditions();
          //Set the tncs link
          this.selectedDepartment.extra.hasOwnProperty('terms_and_conditions_link') ? this.tncsUrl = this.selectedDepartment.extra.terms_and_conditions_link : null;
          //Set the privacy link
          this.selectedDepartment.extra.hasOwnProperty('privacy_policy_link') ? this.privacyUrl = this.selectedDepartment.extra.privacy_policy_link : null;
        }

        this.departmentService.getLocation().then(location => {
          this.selectedLocation = location;
        });

        // Get the selected service
        this.servicesService.get().then(service => {
          this.selectedService = service;

          if (this.isBlockBooking && this.optOutFeature) {
            this.optOutOfVatExemption();
          }

          this.price = this.datastoreService.totalPrice;
          this.price = this.getPrice();
          localStorage.setItem('totalPrice', this.price);

          // let durationIndex = service.durations.findIndex(duration => duration == this.datastoreService.selectedDuration);
          // this.price = service.prices[durationIndex];
          // if (this.datastoreService.requiresPayment) {
          // JRNI stores currency in pennies so / 100 to get pound value
          // this.price = this.selectedService.price / 100
          // } else {
          //   this.price = 0;
          // }
        });

        // Get the selected slot
        this.availabilityService.getSelectedSlot().then(slot => {
          this.selectedSlot = slot;
        });
      }
    });
  }

  async optOutOfVatExemption() {

    let params = [];
    for (const slot of this.datastoreService.recurringDates) {
      params.push({
        "company": this.selectedLocation.id,
        "service": this.selectedService.id,
        "date": slot.date,
        "duration": parseInt(this.datastoreService.selectedDuration),
      });
    }

    const optOut: any = await this.capitaService.vatExemption(this.selectedLocation.id, params);

    if (optOut.hasOwnProperty("exempt")) {
      this.optOutVisbile = optOut.exempt;
    }
    if (this.optOutVisbile) {
      this.coupon = true;
      this.price = this.getPrice();
      localStorage.setItem('totalPrice', this.price);
    }
  }

  getTermsAndConditions() {
    if (this.selectedDepartment.hasOwnProperty('extra')) {
      this.displayTnCs = true;
      // Check if the terms and conditions are on/off
      if (this.selectedDepartment.extra.hasOwnProperty('display_tncs') && this.selectedDepartment.extra.display_tncs.toLowerCase() === 'yes') {
        this.termsAndConditions = '';
        // Loop through all the extra pre defined fields
        const extraQuestions = this.selectedDepartment.extra;
        for (const key in extraQuestions) {
          if (extraQuestions.hasOwnProperty(key)) {
            const element = extraQuestions[key];
            if (key.indexOf('terms_and_conditions') >= 0) {
              if (!this.termsAndConditions) {
                this.termsAndConditions += element;
              } else {
                this.termsAndConditions += ' ' + element;
              }
            }
          }
        }
      } else {
        this.displayTnCs = false;
      }
    }
  }

  // create client
  async createClient() {
    let customerQs = [];

    // For each question in the form check if the customer question exists and if so add to customerAnswers []
    for (const control in this.datastoreService.customerDetailsForm.value) {
      if (this.datastoreService.customerDetailsForm.value.hasOwnProperty(control)) {
        const element = this.datastoreService.customerDetailsForm.value[control];
        const question = this.datastoreService.customerQuestions.filter(question => question.id.toString() === control);
        let q = {};
        if (question.length > 0 && element !== "") {
          q = {
            id: question[0].id,
            answer: element
          }

          customerQs.push(q);
        }
      }
    }

    const params = {
      first_name: this.datastoreService.customerDetailsForm.value.first_name,
      last_name: this.datastoreService.customerDetailsForm.value.last_name,
      email: this.datastoreService.customerDetailsForm.value.email_address,
      mobile: this.datastoreService.customerDetailsForm.value.mobile_number,
      address1: this.datastoreService.customerDetailsForm.value.address1,
      address2: this.datastoreService.customerDetailsForm.value.address2,
      address4: this.datastoreService.customerDetailsForm.value.address4,
      // postcode: this.datastoreService.customerDetailsForm.value.postcode.trim().replace(" ", ""),
      client_type: "contact",
      questions: customerQs
    };

    this.selectedDepartment
      .$post("client", {}, params)
      .then(async (clientRes) => {
        this.datastoreService.client = clientRes;
      });
  }

  async addItem() {
    // Get the selected slot from the previous page
    const selectedSlot = await this.availabilityService.getSelectedSlot();

    // Add item to the basket
    return await this.basketService.addItem(
      this.selectedLocation,
      this.selectedService.id,
      parseInt(this.datastoreService.selectedDuration),
      selectedSlot.datetime,
      // [] // replace with below once booking questions are working
      this.bookingAnswers,
      this.datastoreService.selectedResource
    );
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  async checkout(vatOptOut) {

    this.vatOptOut = vatOptOut;
    if (this.vatOptOut) {
      // undo the price change
      this.price = this.originalPrice;
      localStorage.setItem('totalPrice', this.price);
      this.coupon = false;
      this.couponApplied = false;
    }

    const bookingDetails = {
      "location": this.selectedLocation.name,
    }

    localStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));
    this.spinner.show();
    this.submitted = true;
    this.tncForm.markAllAsTouched();

    // Checks if terms and conditions form is invalid
    if (this.displayTnCs && this.tncForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Checks if privacy statement form is invalid
    if (this.privacyStatementForm.invalid) {
      this.spinner.hide();
      return;
    }

    let noNotifications = true;

    const skipPayment = JSON.parse(sessionStorage.getItem("skipPayment"));
    if (skipPayment) {
      noNotifications = false;
    }
    const member = await this.memberService.getMember();
    const selectedSlot = await this.availabilityService.getSelectedSlot();
    if (!this.isBlockBooking) {
      var availability: any = { availability: false }
      if (this.datastoreService.selectedResource !== "" && this.datastoreService.selectedResource !== undefined) {
        availability = await this.apiService.checkSlotAvailability(this.selectedLocation, selectedSlot.datetime, this.datastoreService.selectedResource)
      } else { // if does not have a resource set, we need to pick out one that is availlable  
        for (let i in this.datastoreService.resources) {
          availability = await this.apiService.checkSlotAvailability(this.selectedLocation, selectedSlot.datetime, this.datastoreService.resources[i].resource_id)
          if (availability.available) {
            this.datastoreService.selectedResource = this.datastoreService.resources[i].resource_id;
            break;
          }
        }
      }

      if (!availability.available) {
        this.availabilityWarning = true;
        this.spinner.hide();
        return;
      } else {
        this.availabilityWarning = false;
      }
    }

    for (let i in this.bookingAnswers) {
      const questionObj: any = this.bookingQuestions.filter(question => parseInt(question.id) == this.bookingAnswers[i].id);
      const question = questionObj[0];
      if (question.detail_type == "radio-price" || question.detail_type == "radio") {
        const answer = question.options.filter(option => parseInt(option.id) == this.bookingAnswers[i].answer);
        this.bookingAnswers[i].answer = answer[0].name
      }
      if (question.detail_type === 'check' || question.detail_type === 'check-price') {
        if (this.bookingAnswers[i].answer === true) {
          this.bookingAnswers[i].answer = 1;
        }
      }
    }

    if (this.isBlockBooking) {
      this.checkoutForBlockBookings(noNotifications, member);
      return;
    }

    await this.availabilityService.getSelectedSlot().then(async (selectedSlot) => {

      const addItem = await this.addItem();

      if (addItem != "Error") {
        this.bookingRes = addItem;

        this.basketService.checkoutBasket(this.selectedLocation, member, noNotifications).then(async (res) => {
          // Get the purchase
          this.basketService.getPurchase().then(purchase => {
            purchase.$get('bookings').then(bookings => {
              localStorage.setItem("selectedService", JSON.stringify(this.selectedService, this.getCircularReplacer()));
              localStorage.setItem("selectedSlot", JSON.stringify(this.selectedSlot, this.getCircularReplacer()));
              localStorage.setItem("purchase", JSON.stringify(purchase));
              localStorage.setItem("booking", JSON.stringify(bookings[0]));

              this.apiService.logPaymentRequest(
                this.selectedLocation,
                this.selectedService,
                res.id,
                res.price
              );

              if (this.datastoreService.requiresPayment) {
                this.completePayment(purchase, bookings[0], member);
              } else {
                this.router.navigate(['/confirmation']);
              }
            });
          });
        });
      } else {
        this.spinner.hide();
        this.scrollToTop();
        this.availabilityWarning = true;
      }

    });
  }

  async checkoutForBlockBookings(noNotifications, member) {
    const slots = this.datastoreService.recurringBookingSlots;
    let addItem: any;
    for (let slot of slots) {
      let resource_id = -1;
      var availability: any = { availability: false }
      if (this.datastoreService.selectedResource !== "" && this.datastoreService.selectedResource !== undefined) {
        availability = await this.apiService.checkSlotAvailability(this.selectedLocation, slot.datetime, this.datastoreService.selectedResource)
        resource_id = this.datastoreService.selectedResource;
      } else { // if does not have a resource set, we need to pick out one that is availlable  
        for (let i in this.datastoreService.resources) {
          availability = await this.apiService.checkSlotAvailability(this.selectedLocation, slot.datetime, this.datastoreService.resources[i].resource_id)
          if (availability.available) {
            resource_id = this.datastoreService.resources[i].resource_id;
            break;
          }
        }
      }

      if (!availability.available) {
        this.availabilityWarning = true;
        this.spinner.hide();
        return;
      } else {
        this.availabilityWarning = false;
      }


      // Add item to the basket
      addItem = await this.basketService.addItem(
        this.selectedLocation,
        this.selectedService.id,
        parseInt(this.datastoreService.selectedDuration),
        slot.datetime,
        // [] // replace with below once booking questions are working
        this.bookingAnswers,
        resource_id
      );
    }

    if (addItem != "Error") {
      const checkout = await this.basketService.checkoutBasket(this.selectedLocation, member, noNotifications);
      if (checkout) {
        // Get the purchase
        const purchase = await this.basketService.getPurchase();
        const bookings = await purchase.$get('bookings');
        localStorage.setItem("purchase", JSON.stringify(purchase));
        localStorage.setItem("booking", JSON.stringify(bookings[0]));

        if (this.coupon) {
          // now we must change the price of the purchase to match the new discounted value
          for (const booking of bookings) {
            const priceUpdate = await this.apiService.updatePrice(booking.id, purchase.id, this.selectedDepartment.id, (this.price / bookings.length), 0);
            localStorage.setItem("booking", JSON.stringify(priceUpdate));
          }
          const updatedPurchase = await this.apiService.findPurchase(bookings[0].company_id, purchase.id);
          localStorage.setItem("purchase", JSON.stringify(updatedPurchase));
          this.couponApplied = true;
        }

        for (let slot of slots) {
          this.bookingService.addExtraResources(slot, bookings[0], slot.resource_id);
        }

        if (this.datastoreService.requiresPayment) {
          if (this.datastoreService.skipPayment) {
            this.spinner.hide();
            this.router.navigate(['/block-booking-confirmation']);
          } else {
            this.completePayment(purchase, bookings[0], member)
          }
        } else {
          this.spinner.hide();
          this.router.navigate(['/block-booking-confirmation']);
        }
      }
    } else {
      this.spinner.hide();
      this.scrollToTop();
      this.availabilityWarning = true;
    }

  }

  async completePayment(purchase, booking, member): Promise<any> {
    // make a request to the payment service
    try {
      const redir = await this.capitaService.invoke(
        this.selectedLocation,
        booking,
        purchase,
        member,
        booking.service_name,
        'MISC',
        this.price,
        this.vatOptOut
      );

      if (redir['invoke_status'] === 'SUCCESS') {
        localStorage.setItem('scp', redir['scp']);
        window.location = redir['url'];
      }
    }
    catch (err) {
      this.apiService.logPaymentExtra(
        this.selectedLocation,
        this.selectedService,
        purchase.id,
        {
          "purchase": purchase,
          "invoke_error": err
        }
      );
      this.basketService.deleteAllBaskets(this.selectedLocation);
      this.spinner.hide();
    }
  }

  routerGoBack() {
    this.router.navigate(['/booking-details']);
  }

  backToAvailability() {
    this.router.navigate(['/calendar']);
  }

  restartJourney() {
    this.router.navigate(['/services']);
  }


  getAnswer(id) {
    try {
      const answerObj: any = this.bookingAnswers.filter(question => parseInt(question.id) == id);
      const questionObj: any = this.bookingQuestions.filter(question => parseInt(question.id) == id);
      const question = questionObj[0];
      if (answerObj.length > 0 || (question.detail_type == "check-price" || question.detail_type == "check")) {
        if (question.detail_type == "select-price" || question.detail_type == "select") {
          const answer = question.options.filter(option => option.name == answerObj[0].answer);
          if (answer.length > 0) {
            if (answer[0].price) {
              return answer[0].name + " £" + (answer[0].price / 100).toFixed(2)
            }
            return answer[0].name
          }
        }
        if (question.detail_type == "radio-price" || question.detail_type == "radio") {
          const answer = question.options.filter(option => parseInt(option.id) == answerObj[0].answer);
          if (answer.length > 0) {
            if (answer[0].price) {
              return answer[0].name + " £" + (answer[0].price / 100).toFixed(2)
            }
            return answer[0].name
          }
        }
        if (question.detail_type == "check-price" || question.detail_type == "check") {
          let checkAns = "No";
          if (answerObj[0]) {
            if (answerObj[0].answer) {
              checkAns = "Yes"
            }
            if (question.price) {
              return checkAns + " £" + (question.price / 100).toFixed(2)
            }
          }
          return checkAns
        }
        if (question.price) {
          return answerObj[0].answer + " £" + (question.price / 100).toFixed(2)
        }
        if (question.detail_type == "date") {
          return moment(answerObj[0].answer).format("DD/MM/YY")
        }
        return answerObj[0].answer;
      } else {
        return "";
      }
    } catch (err) {
      console.log(err)
      return "";
    }
  }


  showConditional(question: any): boolean {
    try {
      if (question.settings.conditional_question !== undefined) {
        const conditionalParent = this.bookingQuestions.filter(listQuestion => parseInt(listQuestion.id) == question.settings.conditional_question.toString())[0]
        let conditionalParentAnswer: any = this.bookingAnswers.filter(answer => parseInt(answer.id) == question.settings.conditional_question.toString())
        if (conditionalParentAnswer[0]) {
          conditionalParentAnswer = conditionalParentAnswer[0].answer
        } else {
          conditionalParentAnswer = false;
        }
        for (const key in question.settings.conditional_answers) {
          const k: any = question.settings.conditional_answers[key];
          if (conditionalParent.detail_type === 'select' || conditionalParent.detail_type === 'select-price') {
            if (k === 0) {
              return false
            } else {
              const answer = conditionalParent.options.filter(option => option.id == key)
              if (answer.length > 0) {
                if (answer[0].name === conditionalParentAnswer.toString()) {
                  return true && this.showConditional(conditionalParent);
                }
              }
            }
          } else {
            if (key.substring(0, 1) === 'c') { // this means it's a checkbox
              if ((question.settings.conditional_answers[key] === '1') === conditionalParentAnswer) {
                return true && this.showConditional(conditionalParent);
              }
            } else {
              if (key === conditionalParentAnswer.toString()) {
                return true && this.showConditional(conditionalParent);
              }
            }
          }
        }
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err)
      return false;
    }
  }

  getPrice() {
    try {
      let price = 0;
      for (let i in this.bookingAnswers) {
        const answerObj: any = this.bookingAnswers[i];
        const questionObj: any = this.bookingQuestions.filter(question => parseInt(question.id) == answerObj.id);
        const question = questionObj[0];
        if (question.detail_type == "select-price") {
          const answer = question.options.filter(option => option.name == answerObj.answer);
          if (answer.length > 0) {
            if (answer[0].price) {
              price += answer[0].price
            }
          }
        }
        if (question.detail_type == "radio-price") {
          const answer = question.options.filter(option => parseInt(option.id) == answerObj.answer);
          if (answer.length > 0) {
            if (answer[0].price) {
              price += answer[0].price
            }
          }
        }
        if (question.price) {
          price += question.price
        }
      }
      if (this.isBlockBooking) {
        const slots = this.datastoreService.recurringBookingSlots;
        price = price * slots.length
      }
      let finalPrice = price + this.price;
      // set the original price
      this.originalPrice = finalPrice;
      if (this.coupon) {
        finalPrice = Math.round( finalPrice / 1.2 );
      }
      return finalPrice;
    } catch (err) {
      console.log(err)
      return 0;
    }
  }

  // Scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  // puts back below next when on a mobile device
  mobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}