import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/jrni/member.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-past-bookings',
  templateUrl: './past-bookings.component.html',
  styleUrls: ['./past-bookings.component.scss']
})
export class PastBookingsComponent implements OnInit {
  pastBookings = [];

  constructor(
    private memberService: MemberService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.memberService.getMember().then(member => {
      this.memberService.getPastBookings(member).then(bookings => {
        this.pastBookings = bookings;
        this.pastBookings.forEach(booking => {          
          booking.$getCompany().then(location => {
            booking.location = location.name
          });
        })
        this.spinner.hide();
      });
    });
  }
}
