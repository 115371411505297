import { Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/services/jrni/client.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { MemberService } from 'src/app/services/jrni/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/services/jrni/login.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  selectedDepartment;
  pageTitle: string;
  registerPageText: string;
  customerQuestions = [];
  emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  submitted: boolean;
  requiresFullRegistration = false;
  allowsMembers: boolean;
  dateQuestions: any = [];


  @ViewChild('day', {static: false}) day: ElementRef | undefined;
  @ViewChild('month',  {static: false}) month: ElementRef | undefined;
  @ViewChild('year',  {static: false}) year: ElementRef | undefined;

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private clientService: ClientService,
    private memberService: MemberService,
    private spinner: NgxSpinnerService,
    private loginService: LoginService
  ) { }

  // Initialise the form
  customerForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
    confirmEmail: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
  }, {
    validators: [this.matchingEmailsValidator('email', 'confirmEmail')]
  });

  // Getter for easy access to form fields
  get form() { return this.customerForm.controls; }

  // On input entry checks if email and confirm email match
  matchingEmailsValidator(emailKey: string, confirmEmailKey: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {

      let email = group.controls[emailKey];
      let confirmEmail = group.controls[confirmEmailKey];

      // If the email and confirm email inputs have data
      if (email.value.length > 0 && confirmEmail.value.length > 0) {
        // Check if the emails match
        if (email.value !== confirmEmail.value) {
          confirmEmail.setErrors({ mismatch: true });
          return {
            mismatch: true
          };
        } else if (confirmEmail.errors && confirmEmail.errors.mismatch) {
          // If the errors object does not contain any other errors then set errors {} to null
          if (Object.keys(confirmEmail.errors).length == 1) {
            confirmEmail.setErrors(null);
          } else {
            // Delete the mismatch error from the object as we do not want to remove any other errors that exist in the {}
            delete confirmEmail.errors.mismatch;
          }
        }
      }
    };
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.value !== this.customerForm.get("confirm_password").value) {
      return { invalid: true };
    }
  }

  routerGoBack() {
    this.router.navigate(['/login']);
  }

  restartJourney() {
    this.router.navigate(['/locations']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
        // let registrationOption = 'register complete details';
        // if (registrationOption.toLowerCase() === 'register complete details') {
        //   this.requiresFullRegistration = true;
        //   this.customerForm.addControl('first_name', new FormControl('', Validators.required));
        //   this.customerForm.addControl('last_name', new FormControl('', Validators.required));
        //   this.customerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(5)]));
        //   this.customerForm.addControl('password', new FormControl('', Validators.required));
        // }
      } else {
        this.selectedDepartment = selectedDepartment;
        let registrationOption;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('registration_page_title') ? this.pageTitle = this.selectedDepartment.extra.registration_page_title : this.pageTitle = "Registration title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('registration_page_text') ? this.registerPageText = this.selectedDepartment.extra.registration_page_text : null;
          // Get the registration option
          this.selectedDepartment.extra.hasOwnProperty('registration_option') ? registrationOption = this.selectedDepartment.extra.registration_option : null;
        }

        // If the department takes members then add a password field to the form
        if (this.selectedDepartment.$has('login')) {
          this.allowsMembers = true;
          this.customerForm.addControl('password', new FormControl('', Validators.compose([Validators.required, Validators.pattern('(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$')])));
          this.customerForm.addControl('confirm_password', new FormControl('', Validators.compose([Validators.required, Validators.pattern('(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$')])));
        }

        // If the register option is set to complete a full registration then capture the following details
        if (registrationOption.toLowerCase() === 'register complete details') {
          this.requiresFullRegistration = true;
          this.customerForm.addControl('first_name', new FormControl('', Validators.required));
          this.customerForm.addControl('last_name', new FormControl('', Validators.required));          
          this.customerForm.addControl('address1', new FormControl('', Validators.required));
          this.customerForm.addControl('address2', new FormControl(''));
          this.customerForm.addControl('address4', new FormControl('', Validators.required));
          this.customerForm.addControl('postcode', new FormControl('', Validators.required));
          this.customerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.pattern("[0-9 ]{11}")]));
          this.customerForm.addControl('password', new FormControl('', [Validators.required]));
        }

        // Get the customer questions
        this.departmentService.getCustomerQuestions().then(questions => {
          this.customerQuestions = questions.filter(question => !question.admin_only);

          for (let index = 0; index < this.customerQuestions.length; index++) {
            const question = this.customerQuestions[index];
            question.visible = false;
            question.reference = question.id;
            if (!question.admin_only) {
              if (!question.settings.hasOwnProperty('conditional_question')) {
                question.visible = true;
              }

              const questionControl = question.id;
              const id = question.id;

              if (question.default == 1 && question.detail_type == 'check' || question.default == 1 && question.detail_type == 'check-price') {
                if (question.required) {
                  this.customerForm.addControl(questionControl, new FormControl(true, Validators.required));
                } else {
                  this.customerForm.addControl(questionControl, new FormControl(true));
                }
              } else if (question['detail_type'] == 'date') {
                question.day_id = `${id}_day`;
                if (question['required']) {
                  // Validators.required
                  this.customerForm.addControl(`${id}_day`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(31)]));
                  this.customerForm.addControl(`${id}_month`, new FormControl('', [Validators.required, Validators.min(1), Validators.max(12)]));
                  this.customerForm.addControl(`${id}_year`, new FormControl('', [Validators.required, Validators.min(1900), Validators.maxLength(4)]));
        
                  this.dateQuestions.push(this.customerForm.controls[`${id}_day`]);
                  this.dateQuestions.push(this.customerForm.controls[`${id}_month`]);
                  this.dateQuestions.push(this.customerForm.controls[`${id}_year`]);
                  this.dateQuestions.push(id);
                }
                else {
                  this.customerForm.addControl(`${id}_day`, new FormControl('', [Validators.min(1), Validators.max(31)]));
                  this.customerForm.addControl(`${id}_month`, new FormControl('', [Validators.min(1), Validators.max(12)]));
                  this.customerForm.addControl(`${id}_year`, new FormControl('', [Validators.min(1900), Validators.maxLength(4)]));
        
                  this.dateQuestions.push(this.customerForm.controls[`${id}_day`]);
                  this.dateQuestions.push(this.customerForm.controls[`${id}_month`]);
                  this.dateQuestions.push(this.customerForm.controls[`${id}_year`]);
                  this.dateQuestions.push(id);
                }
              } 
              else {
                if (question.required) {
                  this.customerForm.addControl(questionControl, new FormControl('', Validators.required));
                } else {
                  this.customerForm.addControl(questionControl, new FormControl(''));
                }
              }
            }
          }
        this.spinner.hide();
        }, err => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
          this.spinner.hide();
        });
      }
    });
  }


  checkPassword(value: any) {
    if (this.customerForm.value.password != this.customerForm.value.confirm_password) {
      this.customerForm.controls.confirm_password.setErrors({ mismatch: true })
    } else {
      this.customerForm.controls.confirm_password.setErrors(null);
    }
     
    if(this.customerForm.value.password.length < 8 || !this.containsUpperAndLowerCase(this.customerForm.value.password) || !this.containsSpecialCharacter(this.customerForm.value.password)) {
      this.customerForm.controls.password.setErrors({ invalid: true });
    } else {
      this.customerForm.controls.password.setErrors(null);
    }

  }




  onQuestionChange() {
    const customerQuestions = this.customerQuestions;
    // Conditional questions logic
    for (let index = 0; index < customerQuestions.length; index++) {
      const q = customerQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty('conditional_question')) {
        // Get the conditional question based on the id set in q
        const conditionalQ = customerQuestions.filter(question => question.id == q.settings.conditional_question);
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(option => option.name == questionControl.value);

        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (conditionalQ.length > 0 && conditionalQ[0].visible && option.length > 0 && q.settings.conditional_answers.hasOwnProperty(option[0].id)) {
          this.form[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.form[q.id].setValue('');
          this.form[q.id].disable();
        }
      }
    }
  }

  async onSubmit() {
    this.customerForm.markAllAsTouched();

      // Chunk dates into array
      const dateQuestions = _.chunk(this.dateQuestions, 4);

      dateQuestions.forEach((dateChunks: any) => {
        const date = moment(`${dateChunks[0].value}-${dateChunks[1].value}-${dateChunks[2].value}`, "DD-MM-YYYY").toDate();

        // check if date is valid
        if (isNaN(date.getTime())) {
          this.form[`${dateChunks[3]}_day`].setErrors({ invalid: true })
          return;
        }

        // check if any date field is blank
        if (!(dateChunks[0].value && dateChunks[1].value && dateChunks[2].value)) {
          this.form[`${dateChunks[3]}_day`].setErrors({ required: true })
          return;
        }

        if (dateChunks[2].status === "INVALID") {
          this.form[`${dateChunks[3]}_day`].setErrors({ required: true })
          return;
        } else {
          this.form[`${dateChunks[3]}_day`].setErrors(null);
          return;
        }
      });

    this.submitted = true;

    if (this.customerForm.invalid) {
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      this.scrollToTop();
      return;
    } else {
      if (!this.requiresFullRegistration) {
        // Get the question that will act as the last name of the client
        this.customerForm.value.last_name = this.form[this.selectedDepartment.extra.registration_question_name].value;
      }
      // Set the answers on the customer form being passed in as the client
      this.customerForm.value.questions = [];

      // For each question in the form check if the customer question exists and if so add to customerAnswers []
      for (const control in this.form) {
        if (this.form.hasOwnProperty(control)) {
          const element = this.form[control];
          let question = this.customerQuestions.filter(question => question.id.toString() === control);

          
          // if(question.length > 0 && question[0].detail_type === "check"){
            // if(element.value){
            //   element.setValue("1")
            // } else {
              //   element.setValue("0")              
              // }
          //   console.log(element);
          // }

          if(control.includes("_day")) {            
            question = this.customerQuestions.filter(question => question.id.toString() === control.split("_")[0]);
          }

          if (question.length > 0 && (element.value.length > 0 || element.value)) {
            // Set a question object
            let q = {
              id: question[0].id,
              answer: element.value
            }

            if(question[0].detail_type === "check" && element.value){
              q = {
                id: question[0].id,
                answer: "1"
              }
            } 

            if(control.includes("_day")) {
              const day = this.customerForm.controls[control.split("_")[0]+"_day"].value;
              const month = this.customerForm.controls[control.split("_")[0]+"_month"].value;
              const year = this.customerForm.controls[control.split("_")[0]+"_year"].value;

              const date = moment(year +"-"+ month +"-"+ day).format();

              q = {
                id: parseInt(control.split("_")[0]),
                answer: date
              }

            }


            this.customerForm.value.questions.push(q);
          }
        }
      }
      // Create client
      const clientExists = await this.clientService.create(this.selectedDepartment, this.customerForm.value);

      if (clientExists && clientExists.auth_token) {
        await this.memberService.setMember(clientExists);
        this.loginService.setLogin(clientExists, null);
        // If location is before services then redirect to locations else redirect to calendar

        this.router.navigate(['/booking-details']);

      } else if (clientExists && clientExists.response.status == 401) {
        this.alertService.warn(this.translateService.instant('REGISTRATION.ACCOUNT_EXISTS', { email: this.customerForm.value.email }));
        this.scrollToTop();
      } else {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.scrollToTop();
      }
    }
  }

   // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  containsUpperAndLowerCase(str) {
    return (/^(?=.*[a-z])(?=.*[A-Z])/.test(str));
  }

  containsSpecialCharacter(str) {
    return (/^(?=.*[!@#$%^&*_0-9]).*$/.test(str));
  }
  
  // puts back below next when on a mobile device
  mobile(){
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}
