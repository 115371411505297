import { Component, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { DatastoreService } from 'src/app/services/datastore.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { CapitaService } from 'src/app/services/jrni/capita.service';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { context } from 'bookingbug-core-js';
import { BookingService } from 'src/app/services/jrni/booking.service';
import { EmailService } from 'src/app/services/email.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MemberService } from 'src/app/services/jrni/member.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/jrni/api.service';
import { LoginService } from 'src/app/services/jrni/login.service';
import * as moment from 'moment';

@Component({
  selector: 'app-block-purchase-review',
  templateUrl: './block-purchase-review.component.html',
  styleUrls: ['./block-purchase-review.component.scss']
})
export class BlockPurchaseReviewComponent implements OnInit {

  pageTitle: string;
  bookingReviewPageText: string;
  bookingId: any = null;
  companyId: any = null;
  booking;
  purchase;
  selectedDepartment;
  member;
  displayTnCs;
  termsAndConditions;
  selectedDuration;
  selectedService;
  bookingQuestions;
  isBlockBooking;
  price;
  bookingAnswers = [];
  recurringDates = [];
  optOutVisbile = false;
  adminVatOptout = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private departmentService: DepartmentService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private capitaService: CapitaService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.spinner.show();
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('bookingType');
    sessionStorage.removeItem('skipPayment');

    localStorage.removeItem('auth_token');
    localStorage.removeItem('recurringDates');
    localStorage.removeItem('hideNavigation');
    localStorage.removeItem('booking');
    localStorage.removeItem('purchase');
    localStorage.removeItem('totalPrice');
    localStorage.removeItem('bookingDetails');
    this.scrollToTop();

    this.datastoreService.checkShouldHideNavigation();

    this.route.queryParams.subscribe(params => {
      this.bookingId = params['b'];
      this.companyId = params['c'];
    });

    // this.bookingId = this.bookingService.decodeBookingRef(this.bookingId);
    this.loadBooking();
  }

  async loadBooking() {
    await this.departmentService.getParentDepartment().then(async (company) => {
      if (company.hasOwnProperty('extra')) {
        // Set the title on the login page to the title from the business question
        company.extra.hasOwnProperty('summary_page_title') ? this.pageTitle = company.extra.summary_page_title : this.pageTitle = "Summary title not set";
        // Set the extra text on the page to the text taken from the business question
        company.extra.hasOwnProperty('summary_page_text') ? this.bookingReviewPageText = company.extra.summary_page_text : null;
      }

      const tmpCompany = {
        id: this.companyId
      };

      this.bookingService.getBookingFromId(this.bookingId, tmpCompany).then(async (booking: any) => {
        this.booking = booking;
        if (booking) {
          let purchase = false;
          if (booking['purchase']) {
            this.purchase = purchase = booking['purchase'];
          }
          else {
            this.restartJourney();
          }

          this.bookingService.loadBooking(booking, purchase, booking['company_id']).then(async (res) => {

            if (purchase['_embedded'].bookings.length > 0) {

              let params = [];

              for (const b of purchase['_embedded'].bookings) {

                params.push({
                  "company": b.company_id,
                  "service": b.service_id,
                  "date": b.datetime,
                  "duration": b.duration,
                });

                this.recurringDates.push({
                  'date': b.datetime,
                  'formatted': moment(b.datetime).format('DD MMM YYYY, HH:mm') + ", " + (b.duration / 60) + "hour(s)",
                  'available': b.avail == 1 ? true : false
                });
              }
              
              if(booking.settings.channel == 'admin'){
                this.optOutVisbile = false;
                this.adminVatOptout = booking.settings.vatExemption;
              } else {
                const optOut: any = await this.capitaService.vatExemption(booking['company_id'], params);
                if (optOut.hasOwnProperty("exempt")) {
                  this.optOutVisbile = optOut.exempt;
                }
              }

            }

            this.selectedService = this.datastoreService.selectedService;
            this.datastoreService.skipPayment = false;
            sessionStorage.setItem('skipPayment', JSON.stringify(this.datastoreService.skipPayment));

            this.selectedDuration = booking.duration;
            this.bookingQuestions = booking.questions;
            this.isBlockBooking = true;
            this.price = purchase['total_price'];

            sessionStorage.setItem("bookingType", JSON.stringify((`${'block'}`)));
            // localStorage.setItem('totalPrice', this.price);

            localStorage.setItem('booking', JSON.stringify(this.booking));
            localStorage.setItem('purchase', JSON.stringify(this.purchase));

            localStorage.setItem('recurringDates', JSON.stringify(this.recurringDates));

            // get the location
            const children = await company.$getChildren();
            let location = { name: '' };
            for (const child of children) {
              if (child.id === booking['company_id']) {
                location = child;
                break;
              }
            }

            // make the booking details object
            const bookingDetails = {
              location: location.name
            };
            localStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));

            // get the real company
            this.departmentService.get().then(company => {
              this.selectedDepartment = company;

              // get the member
              this.datastoreService.getClient().then(async (member) => {
                this.member = member;
                const parent = await company.$getParent();

                const token = await this.loginService.ssoToken(member, parent);
                const loginData = await this.loginService.ssoLogin({
                  root: context.apiUrl,
                  company_id: parent.id
                }, token);
                this.member = loginData;

                this.spinner.hide();
              });
            });
          });
        }
      });
    });
  }

  async checkout(optOutVat) {

    if(this.adminVatOptout){
      optOutVat = true;
    }

    this.spinner.show();
    try {
      const redir = await this.capitaService.invoke(
        this.selectedDepartment,
        this.booking,
        this.purchase,
        this.member,
        this.booking.service_name,
        'MISC',
        this.price,
        optOutVat
      );

      if (redir['invoke_status'] === 'SUCCESS') {
        localStorage.setItem('scp', redir['scp']);
        window.location = redir['url'];
      }
    }
    catch (err) {
      console.log(err);
      this.spinner.hide();
    }
  }

  getAnswer(id) {
    const answerObj: any = this.bookingAnswers.filter(question => parseInt(question.id) == id);
    if (answerObj.length > 0) {
      return answerObj[0].answer;
    } else {
      return "";
    }
  }

  getTermsAndConditions() {
    if (this.selectedDepartment.hasOwnProperty('extra')) {
      this.displayTnCs = true;
      // Check if the terms and conditions are on/off
      if (this.selectedDepartment.extra.hasOwnProperty('display_tncs') && this.selectedDepartment.extra.display_tncs.toLowerCase() === 'yes') {
        this.termsAndConditions = '';
        // Loop through all the extra pre defined fields
        const extraQuestions = this.selectedDepartment.extra;
        for (const key in extraQuestions) {
          if (extraQuestions.hasOwnProperty(key)) {
            const element = extraQuestions[key];
            if (key.indexOf('terms_and_conditions') >= 0) {
              if (!this.termsAndConditions) {
                this.termsAndConditions += element;
              } else {
                this.termsAndConditions += ' ' + element;
              }
            }
          }
        }
      } else {
        this.displayTnCs = false;
      }
    }
  }

  restartJourney() {
    this.router.navigate(["/locations"]);
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  // puts back below next when on a mobile device
  mobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

}
