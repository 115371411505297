import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { EmailService } from 'src/app/services/email.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { AlertService } from 'src/app/_alert';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { ApiService } from 'src/app/services/jrni/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { context } from 'bookingbug-core-js';
import { BookingService } from 'src/app/services/jrni/booking.service';
import { AppConfig } from 'src/app/app.config';
@Component({
  selector: 'app-block-booking-confirmation',
  templateUrl: './block-booking-confirmation.component.html',
  styleUrls: ['./block-booking-confirmation.component.scss']
})
export class BlockBookingConfirmationComponent implements OnInit {

  selectedDepartment;
  pageTitle: string;
  confirmationPageText: string;
  purchase;
  bookings;
  bookingReference;
  showBooking;
  selectedService: any;
  selectedLocation: any;
  selectedBooking: any;
  selectedSlot: any;
  bookingDetails: any;
  price: any;
  isBlockBooking = false;
  recurringDates = [];
  skipPayment = false;
  sendAdminEmail = AppConfig.settings.sendAdminEmail;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private memberService: MemberService,
    private translateService: TranslateService,
    private emailService: EmailService,
    private datastoreService: DatastoreService,
    private basketService: BasketService,
    private availabilityService: AvailabilityService,
    private http: HttpClient,
    private apiService: ApiService,
    private dataStore: DatastoreService,
    private bookingService: BookingService
  ) { }

  private url = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    this.price = '';
    this.recurringDates = JSON.parse(localStorage.getItem('recurringDates'));
    this.isBlockBooking = JSON.parse(sessionStorage.getItem("bookingType"));
    this.skipPayment = JSON.parse(sessionStorage.getItem('skipPayment'));
    
    try {
      const bookingDetails = localStorage.getItem('bookingDetails');
      if (bookingDetails) {
        this.bookingDetails = JSON.parse(bookingDetails);
      }
      this.departmentService.getParentDepartment().then(async selectedDepartment => {

        if (selectedDepartment === undefined) {
          this.restartJourney();
        } else {

          let resource = await this.addExtraResources();

          // Set the departments again so it doesn't log user out when going back to services
          this.departmentService.getParentDepartment().then(res => {
            this.departmentService.set(res);
          }, err => {
            this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
            this.spinner.hide();
          });

          this.selectedDepartment = selectedDepartment;
          // Get the configurable data
          if (this.selectedDepartment.hasOwnProperty('extra')) {
            // Set the title on the login page to the title from the business question
            this.selectedDepartment.extra.hasOwnProperty('confirmation_page_title') ? this.pageTitle = this.selectedDepartment.extra.confirmation_page_title : this.pageTitle = "Confirmation title not set";
            // Set the extra text on the page to the text taken from the business question
            this.selectedDepartment.extra.hasOwnProperty('confirmation_page_text') ? this.confirmationPageText = this.selectedDepartment.extra.confirmation_page_text : null;
          }

          const client = await this.memberService.getMember();
          if (client) {
            // Get the purchase and booking from the session storage
            // const purchase = JSON.parse(localStorage.getItem('purchase'));
            const booking = JSON.parse(localStorage.getItem('booking'));
            const purchase:any = await this.apiService.findPurchase(booking.company_id, booking.purchase_id);

            if (booking && booking.service_name.includes("11 a side")) {
              booking.resource_name = "11 a side pitch";
            }

            let requiresPayment;
            sessionStorage.getItem('requiresPayment') === "false" ? requiresPayment = false : requiresPayment = true;
            let skipPayment;
            sessionStorage.getItem('skipPayment') === "false" ? skipPayment = false : skipPayment = true;

            // const driver_email = sessionStorage.getItem('driversEmail');
            if (purchase && booking) {
              if (booking.paid != booking.price && requiresPayment) {
                booking.paid = booking.price;
                if (!skipPayment) {
                  this.apiService.paymentConfirm(purchase.id, (this.dataStore.totalPrice / purchase._embedded.bookings.length), JSON.parse(localStorage.booking).company_id).then(async (res) => {
                    // get the price from the purchase
                    const foundPurchase = await this.apiService.findPurchase(booking.company_id, res['id']);
                    this.price = foundPurchase['total_price'];
                    if(this.sendAdminEmail) {
                      this.bookingService.sendAdminEmail(booking);
                    }
                    // this.emailService.sendEmail('CONFIRMED', booking, booking.email, this.selectedDepartment).subscribe((res) => { })
                      this.apiService.logPaymentExtra(
                        this.dataStore.selectedLocation,
                        this.dataStore.selectedService,
                        purchase.id,
                        {
                          "purchase": purchase.id,
                          "update_booking": { id: res['id'], purchase_id: res['purchase_id'], price: res['price'], paid: res['paid'], updated: res['booking_updated'], notes: res['private_note'] }
                        }
                      );
                      this.spinner.hide();
                  }, err => {
                      this.apiService.logPaymentExtra(
                        this.dataStore.selectedLocation,
                        this.dataStore.selectedService,
                        purchase.id,
                        {
                          "purchase": purchase.id,
                          "update_booking_error": err
                        }
                      );
                    this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
                    console.log(err);
                  })
                }
              }
              else {
                const foundPurchase = await this.apiService.findPurchase(booking.company_id, booking.purchase_id);
                this.price = foundPurchase['original_price'];
                this.spinner.hide();
              }
              // this.emailService.sendEmail('CONFIRMED', booking, booking.email, this.selectedDepartment).subscribe((res) => { })
              this.bookingReference = booking.id;
              this.showBooking = true;
              this.bookings = booking;
              if (!this.bookings.first_name) {
                this.bookings.first_name = purchase._embedded.client.first_name;
              }
              if (!this.bookings.last_name) {
                this.bookings.last_name = purchase._embedded.client.last_name;
              }
     
              localStorage.removeItem('scp');
            } else {
              this.restartJourney();
            }
          } else {
            this.restartJourney();
          }
        }
      });
    }
    catch (err) {
      console.log(err);
      this.spinner.hide();
    }
  }

  blockBooking(index) {
    this.selectedService = JSON.parse(localStorage.getItem('selectedService'));
    this.selectedBooking = JSON.parse(localStorage.booking);
    this.selectedLocation = JSON.parse(localStorage.booking).company_id;

    const slot = this.selectedSlot.associatedSlots[index];
    const tempDateTime = new Date(this.selectedSlot.datetime);
    const endDate = new Date(tempDateTime.getTime() + this.selectedBooking.duration * 60000);

    if (slot) {
      const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
      const body = {
        "host": `${context.apiUrl}`,
        "company_id": `${this.selectedLocation}`,
        "type": "resources",
        "resource_id": `${slot.resource_id}`,
        "start_datetime": `${this.selectedSlot.datetime}`,
        "end_datetime": `${endDate.toISOString()}`,
        "private_note": `Tied to booking: ${this.selectedBooking.id}`
      }
      try {
        this.http.post(`${this.url}/blockBooking`, body, { headers }).toPromise(); // TODO switch all calls to api.bookinglab.co.uk when endpoints set
      }
      catch (err) {
        this.apiService.logPaymentExtra(
          this.dataStore.selectedLocation,
          this.dataStore.selectedService,
          this.dataStore.booking.purchase_id,
          {
            "purchase": this.dataStore.booking.purchase_id,
            "block_booking_error": err
          }
        );
      }
    }
  }


  async addExtraResources() {
    try {
      let slot = JSON.parse(localStorage.getItem('selectedSlot'));
      this.selectedSlot = slot;
      // done at summary stage now
      // if (slot) {
      //   const slotsNeeded = (slot.quantity);
      //   return new Promise(resolve => {
      //     for (let index = 0; index < slotsNeeded; index++) {
      //       this.blockBooking(index);
      //     }
      //     localStorage.removeItem('selectedSlot');
      //     resolve(slot);
      //   });
      // }
    }
    catch (err) {
      this.apiService.logPaymentExtra(
        this.dataStore.selectedLocation,
        this.dataStore.selectedService,
        this.dataStore.booking.purchase_id,
        {
          "purchase": this.dataStore.booking.purchase_id,
          "add_extra_error": err
        }
      );
    }
  }

  restartJourney() {
    localStorage.removeItem('purchase');
    localStorage.removeItem('booking');
    localStorage.removeItem('selectedService');
    localStorage.removeItem('selectedSlot');
    localStorage.removeItem('scp');
    localStorage.removeItem('bookingDetails');
    localStorage.removeItem('hideNav');
    this.router.navigate(['/locations']);
  }

  // print confirmation page
  print() {
    window.print();
  }
  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

}
