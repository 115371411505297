import { Injectable } from '@angular/core';
import { BBService, BBModel, halClient } from 'bookingbug-core-js';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import * as moment from 'moment';
import { context } from 'bookingbug-core-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private memberLoggedIn = new Subject<any>();
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  private url = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';
  member;

  constructor(
    private http: HttpClient
  ) { }

  async getMember() {
    if (this.member) {
      return this.member
    } else {
      if (sessionStorage.getItem("login")) {
        // Get the login stored in the session storage
        const login = JSON.parse(sessionStorage.getItem('login'));
        // Create a member out of the object
        const member = new BBModel.Admin.User(halClient.createResource(login));
        this.setMember(member);
        return member;
      }
    }
  }

  async setMember(member) {
    this.member = new BBModel.Client(member);
    this.setMemberLoggedIn(member);
  }

  getMemberLoggedIn() {
    return this.memberLoggedIn.asObservable();
  }

  setMemberLoggedIn(member: any) {
    this.memberLoggedIn.next(member);
  }

  async checkMemberExists(selectedDepartment, email) {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });

    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/${selectedDepartment.id}/client/find_by_email/${email}`;
    const response = await this.http.get(apiUrl, { headers }).toPromise();
  }

  async updateMember(member, params) {
    const response = await BBService.member.updateMember(member, params);
    const updatedMember = await new BBModel.Member.Member(response);
    sessionStorage.setItem('login', updatedMember.$toStore());
    return response;
  }

  async updatePassword(member, params) {
    return await BBService.login.updatePassword(member, params);
  }

  async getBookings() {
    return await this.member.$getBookings();
  }

  async getBookingsSelectedDate(date) {
    if (this.member && this.member["id"]) {
      return await this.member.$getBookings({ start_date: date, end_date: date });
    }

    return [];
  }

  async getPastBookings(member) {
    const today = moment();
    const lastYear = moment().subtract(1, 'year');
    const params = {
      start_date: lastYear.toISOString().split('T')[0],
      end_date: today.toISOString().split('T')[0]
    }
    return await member.$getBookings(params);
  }

  async updateBooking(companyId, bookingId, paid, purchaseId, client_id) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      host: `${context.apiUrl}`,
      company_id: `${companyId}`,
      booking_id: `${bookingId}`,
      paid,
      purchaseId,
      client_id
    }

    return await this.http.post(`${this.url}/updateBooking`, body, { headers }).toPromise();
  }

  
}
