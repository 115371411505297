import { Injectable } from '@angular/core';
import { BBModel, BBService, context } from 'bookingbug-core-js';
import { halClient } from 'bookingbug-core-js/src/hal';
import { Router } from '@angular/router';
import { MemberService } from './member.service';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private router: Router,
    private memberService: MemberService,
    private http: HttpClient
  ) { }

  private apiServer = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';

  async loginMember(company, loginRequest) {
    return await this.companyLogin(company, {}, loginRequest);
  }

  async resetPassword(company, resetForm) {
    return await BBService.login.sendPasswordReset(company, resetForm);
  }

  companyLogin(company, params, form) {
    return new Promise((resolve, reject) => {
      company.$post('login', params, form).then((login) => {
        sessionStorage.setItem('auth_token', login.auth_token);
        localStorage.setItem('auth_token', login.auth_token);

        return login.$get('member').then((member) => {
          this.setLogin(member, null);
          return resolve(member);
        }
          , (err) => {
            return reject(err);
          }
        );
      }
        , (err) => {
          return reject(err);
        }
      );
    });
  }

  setLogin(member, persist) {
    const auth_token = member.getOption('auth_token');
    sessionStorage.setItem('auth_token', member.auth_token);
    member = new BBModel.Member.Member(member);
    sessionStorage.setItem('login', member.$toStore());
    context.member = member;
    if (persist) {
      // save both auth_token and login to re-use them
      sessionStorage.setItem('login', member.$toStore());
    }
    return member;

  }

  async ssoToken(member, company){
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "first_name": `${member.first_name}`,
      "last_name": `${member.last_name}`,
      "email": `${member.email}`
    }

    return await this.http.post(`${this.apiServer}/sso/token`, body, { headers }).toPromise();
  }

  ssoLogin(options, data) {

    if (sessionStorage.getItem("login")) {
      // Get the login stored in the session storage
      const login = JSON.parse(sessionStorage.getItem('login'));
      // Create a member out of the object
      const member = new BBModel.Admin.User(halClient.createResource(login));
      this.memberService.setMember(member);
      return member;
    } else {
      return new Promise((resolve, reject) => {
        if (!options['root']) {
          options['root'] = '';
        }
        const url = options['root'] + `/api/${context.apiVersion}/login/sso/` + options['company_id'];
        halClient.$post(url, {}, data).then((login) => {
          return login.$get('member').then((member) => {
            member = new BBModel.Member.Member(member);
            this.setLogin(member, true);
            return resolve(member);
          }
          );
        }
          , (err) => {
            return reject(err);
          }
        );
      });
    }
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('auth_token')) {
      return true;
    } else {
      return false;
    }

  }

  logout() {
    sessionStorage.removeItem("auth_token");
    sessionStorage.removeItem("login");
    // remove the value from localStorage
    localStorage.removeItem("auth_token");
    sessionStorage.removeItem('purchase');
    sessionStorage.removeItem('booking');
    sessionStorage.removeItem('requiresPayment');
    sessionStorage.removeItem('driversEmail');

    this.restartJourney();
  }

  restartJourney() {
    this.router.navigate(['/locations']);
  }
}

