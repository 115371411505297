import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { context } from 'bookingbug-core-js';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../../app.config';
import { MemberService } from "./member.service";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiServer = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';

  constructor(private http: HttpClient, private memberService: MemberService) { }

  async getIpAddress() {
    return this.http.get('https://api.ipify.org?format=json').toPromise();
  }

  async logPaymentRequest(company, service, bookingId, amount) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}`};

    const clientAdrr = await this.getIpAddress();

    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "service_id": `${service.id}`,
      "order_id": `${bookingId}`,
      "amount": `${amount}`,
      "client_addr": `${ clientAdrr['ip'] }`,
      "user_agent": navigator.userAgent,
      "client_os": navigator.platform,
      "provider": "capita",
      "type": "req"

    };
    return this.http.post(`${this.apiServer}/logPayment`, body, { headers }).toPromise();
  }

  async logPaymentResponse(company, service, bookingId, responseObj) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}`};

    const clientAdrr = await this.getIpAddress();

    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "service_id": `${service.id}`,
      "order_id": `${bookingId}`,
      "provider": "capita",
      "client_addr": `${ clientAdrr['ip'] }`,
      "response_obj": JSON.stringify(responseObj),
      "type": "res"
    };
    return this.http.post(`${this.apiServer}/logPayment`, body, { headers }).toPromise();
  }

  async logPaymentExtra(company, service, bookingId, extraObj) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}`};

    const clientAdrr = await this.getIpAddress();

    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "service_id": `${service.id}`,
      "order_id": `${bookingId}`,
      "provider": "capita",
      "client_addr": `${ clientAdrr['ip'] }`,
      "extra_obj": JSON.stringify(extraObj),
      "type": "extra"
    };
    return this.http.post(`${this.apiServer}/logPayment`, body, { headers }).toPromise();
  }

  async getUserDefinedFieldValue(company, serviceId, question) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "service_id": `${serviceId}`,
      "question": `${question}`,
    }

    return await this.http.post(`${this.apiServer}/userDefinedFieldValue`, body, { headers }).toPromise();
  }

  async paymentConfirm(purchase, total, company){
    const client = "bookinglab-demo";
    const account_type = "jrni";
    const council = "false";
    // const env = (environment.production ? "" : "demo");
    const env = "";
    const url = `https://3gvlgnl22okz3s2cat6xhvu5ay0ssgcx.lambda-url.eu-west-1.on.aws/?purchase=${purchase}&company=${company}&total=${total}&client=${client}&account_type=${account_type}&council=${council}&env=${env}`;
    
    return this.http.get(url).toPromise();
  }

  async checkSlotAvailability(company, slot, resource_id) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` ,ApiVersion: 'latest'};
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "resource_id": resource_id,
      "slot": slot,
    }

    return this.http.post(`${this.apiServer}/slotAvailability`, body, { headers }).toPromise();
  }

  async findPurchase(company, purchaseId) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` ,ApiVersion: 'latest'};
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company}`,
      "purchase_id": purchaseId,
    }

    return this.http.post(`${this.apiServer}/findPurchase`, body, { headers }).toPromise();
  }

  async applyCoupon(company, basket, coupon) {
    const authToken = localStorage.getItem('auth_token');
    const headers = { 'Content-Type': 'application/json', 'App-Id': AppConfig.settings.core.app_id, 'Auth-Token': authToken };
    const body = {}

    return this.http.put(`${context.apiUrl}/api/${context.apiVersion}/baskets/${basket}/coupons/${coupon}`, body, { headers }).toPromise();
  }

  async updatePrice(bookingId, purchaseId, companyId, price, pay) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      host: `${context.apiUrl}`,
      'booking_id': `${bookingId}`,
      'purchase_id': `${purchaseId}`,
      'company_id': `${companyId}`,
      'price': `${price}`,
      'pay': `${pay}`
    };

    return this.http.post(`${this.apiServer}/updatePrice`, body,  { headers }).toPromise();
  }

}