import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalComponent } from 'src/app/modal/modal.component';
import { MemberService } from 'src/app/services/jrni/member.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-upcoming-bookings',
  templateUrl: './upcoming-bookings.component.html',
  styleUrls: ['./upcoming-bookings.component.scss']
})
export class UpcomingBookingsComponent implements OnInit {
  bookings = [];
  member;

  constructor(
    private memberService: MemberService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.memberService.getMember().then(member => {
      this.member = member;
      this.memberService.getBookings().then(bookings => {
        this.bookings = bookings;

        this.bookings.forEach(booking => {          
          booking.$getCompany().then(location => {
            booking.location = location.name
          });
        })
        
      });
    });
  }

  openCancelDialog(booking): void {
    booking.$getCompany().then(location => {
      const dialogRef = this.dialog.open(ModalComponent, {
        width: '800px',
        data: {
          header: this.translateService.instant('ACCOUNT.CANCEL_BOOKING_HEADER'),
          action: "cancel",
          booking,
          member: this.member,
          location
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.memberService.getBookings().then(bookings => {
          this.bookings = bookings.filter(booking => booking.deleted === false);
          this.cdref.detectChanges();
        });
      });
    });
  }

  openModifyDialog(booking): void {
    this.spinner.show();
    booking.$getCompany().then(location => {
      booking.$getService().then(service => {
        const dialogRef = this.dialog.open(ModalComponent, {
          width: '1000px',
          data: {
            header: this.translateService.instant('ACCOUNT.MODIFY_BOOKING_HEADER'),
            action: "modify",
            booking,
            service,
            location,
            member: this.member
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          this.memberService.getBookings().then(bookings => {
            this.bookings = bookings.filter(booking => booking.deleted === false);
            this.cdref.detectChanges();
          });
        });
        this.spinner.hide();
      });
    });
  }

  openDetailsDialog(booking): void {

    this.spinner.show();
    booking.$getCompany().then(location => {
      booking.$getService().then(service => {

        const dialogRef = this.dialog.open(ModalComponent, {
          width: '1000px',
          data: {
            header: this.translateService.instant('ACCOUNT.DETAILS_BOOKING_HEADER'),
            action: "details",
            booking,
            service,
            location,
            member: this.member
          }
        });
        this.spinner.hide();
      });
    });
  }
}
