import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from 'src/app/services/jrni/member.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { LoginService } from 'src/app/services/jrni/login.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss']
})
export class AccountLoginComponent implements OnInit {
  selectedDepartment;
  pageTitle: string;
  emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  submitted: boolean;
  loginPageText: string;
  resetPasswordField: boolean;
  resetLoginSubmitted: boolean;

  constructor(
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private loginService: LoginService,
    protected alertService: AlertService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  // Define the login form
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
    password: new FormControl('', Validators.required)
  });

  // Define the reset password form
  resetPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegex)]),
  });

  // Getter for easy access to form fields
  get form() { return this.loginForm.controls; }

  // Getter for easy access to form fields
  get resetForm() { return this.resetPasswordForm.controls; }

  // Go back to the booking details page
  routerGoBack() {
    this.router.navigate(['/services']);
  }

  ngOnInit() {

  }

  memberExists() {
    this.memberService.getMember().then(member => {
      if (member) {
        this.router.navigate(['/account']);
      }
    })
  }

  forgottenPassword() {
    !this.resetPasswordField ? this.resetPasswordField = true : this.resetPasswordField = false;
  }

  resetPassword() {
    this.resetLoginSubmitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.loginService.resetPassword(this.selectedDepartment, this.resetPasswordForm.value).then(res => {
        this.alertService.success(this.translateService.instant('LOGIN.RESET_SUCCESS'));
      }).catch((error: HttpErrorResponse) => {
        if (error['response'] && error['response'].status === 404) {
          this.alertService.error(this.translateService.instant('LOGIN.USER_NOT_FOUND'));
        } else {
          this.alertService.error(this.translateService.instant('LOGIN.FAILED'));
        }
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if the login form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.departmentService.getParentDepartment().then(parentCompany => {
        this.departmentService.set(parentCompany);
        this.loginService.loginMember(parentCompany, this.loginForm.value).then(async res => {
          await this.memberService.setMember(res);
          this.router.navigate(['/account']);
        })
          .catch((error: HttpErrorResponse) => {
            if (error || error['response'] && error['response'].status === 401) {
              this.alertService.error(this.translateService.instant('LOGIN.EMAIL_NOT_RECOGNISED'))
            }
          });
      });
    }
  }
}
