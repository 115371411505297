import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MemberService } from 'src/app/services/jrni/member.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  selectedDepartment;
  pageTitle;
  servicePageText;
  servicesList;
  selectedService;

  allServices;
  hourSelected = "peak";
  loading = false;

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    protected alertService: AlertService,
    private servicesService: ServicesService,
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService
  ) { }

  // Go back to the booking details page
  routerGoBack() {
    this.router.navigate(['/locations']);
  }

  restartJourney() {
    this.router.navigate(['/locations']);
  }

  ngOnInit() {
    this.spinner.show();
    sessionStorage.removeItem('purchase');
    sessionStorage.removeItem('booking');
    sessionStorage.removeItem('requiresPayment');
    sessionStorage.removeItem('driversEmail');

    this.datastoreService.bookingForm = null;
    this.datastoreService.selectedDate = null;
    this.datastoreService.selectedSlot = null;
    this.availabilityService.setSelectedSlot(null);
    this.scrollToTop();
    this.setDepartment();

  }

  async setDepartment() {
    this.loading = true;
    this.departmentService.get().then(async selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('services_page_title') ? this.pageTitle = this.selectedDepartment.extra.services_page_title : this.pageTitle = "Services title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('services_page_text') ? this.servicePageText = this.selectedDepartment.extra.services_page_text : null;
        }

        const selectedLocation = await this.departmentService.getLocation();
        const services = await selectedLocation.$getServices();
        this.servicesList = services;

        const bookingType = JSON.parse(sessionStorage.getItem("bookingType"));

        if (sessionStorage.getItem('category')) {
          this.servicesList = this.servicesList.filter(service => service.category_id == sessionStorage.getItem('category')
            && service.extra.booking_type.toLowerCase() == bookingType);
        }

        this.allServices = this.servicesList;

        // default only show peak hours
        this.servicesList = this.servicesList.filter(service => service.extra.hours == "peak" || service.extra.hours == "allyear");

        services.forEach(async service => {
          service.$getImages().then(image => {
            service.image = image[0];
            this.cdref.detectChanges();
          });
        });
        this.loading = false;
        this.spinner.hide();
      }
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.loading = false;
      this.spinner.hide();
    });
  }

  filterHours(event) {
    this.servicesList = this.allServices.filter(service => service.extra.hours == event.target.value || service.extra.hours == "allyear");
  }

  selectService(service) {
    this.selectedService = service;
  }

  continue() {
    if (this.selectedService) {
      this.servicesService.set(this.selectedService);
      //store bookingtype
      this.datastoreService.bookingType = this.selectedService.extra.booking_type.toLowerCase();
      this.datastoreService.skipPayment = this.selectedService.extra.skip_payment == "Yes" ? true : false;
      this.datastoreService.selectedResource = "";
      this.datastoreService.resources = [];
      localStorage.removeItem("selectedResource")
      localStorage.removeItem("resources")
      sessionStorage.setItem('skipPayment', JSON.stringify(this.datastoreService.skipPayment));
      this.router.navigate(['/calendar']);
    } else {
      this.alertService.warn("Please select a service before proceeding");

      this.scrollToTop();
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  clickRadio(radioId) {
    document.getElementById(radioId).click();
  }

  getHeight(){ //this sets the description's background box to match the size of it's parent dynamically
    let listH = document.body.getElementsByClassName("services-list")[0].clientHeight;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      listH = listH / 3; // on moble elements are stacked
    }
    for(let i in this.servicesList){
      const row2: any = document.getElementById(this.servicesList[i].name + "-description");
      row2.style.height = listH + 10 + "px";
    }
  }
}
