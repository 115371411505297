import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AlertService } from 'src/app/_alert';
import { MemberService } from 'src/app/services/jrni/member.service';
import * as _ from 'underscore';
import { DatastoreService } from 'src/app/services/datastore.service';
import { DobValidatorService } from 'src/app/services/dob-validator.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
  selectedDepartment;
  selectedService;
  pageTitle: string;
  bookingDetailsPageText: string;
  bookingQuestions = [];
  submitted: boolean;
  selectedLocation;
  requiresFullRegistration = false;
  organisationQuestions = [];
  bookingAnswers = [];
  today = new Date();
  selectedFile: string;
  paymentValidator = /^[0-9]{3}-[0-9]{3}$/;
  questionIdToIndex = {};

  constructor(
    private router: Router,
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private formBuilder: FormBuilder,
    private basketservice: BasketService,
    private alertService: AlertService,
    private availabilityService: AvailabilityService,
    private translateService: TranslateService,
    private memberService: MemberService,
    private datastoreService: DatastoreService,
    private dobValidatorService: DobValidatorService,
    private spinner: NgxSpinnerService
  ) { }

  // Initialise the customer form
  customerDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email_address: new FormControl('', Validators.email)
  });

  // Initialise the form
  bookingQuestionsForm: FormGroup = this.formBuilder.group({});

  // Getter for easy access to form fields
  get form() { return this.bookingQuestionsForm.controls; }

  routerGoBack() {
    this.router.navigate(['/calendar']);
  }

  restartJourney() {
    this.router.navigate(['/services']);
  }

  async createBasket(selectedDepartment, location) {
    // Delete any exisitng baskets to avoid conflicts
    await this.basketservice.deleteAllBaskets(selectedDepartment);

    // Delete any exisitng baskets to avoid conflicts
    if (this.basketservice.currentBasket) {
      await this.basketservice.delete(this.selectedLocation, this.basketservice.currentBasket);
    }
    // Create a basket
    await this.basketservice.createBasket(location).then(basket => {
      this.basketservice.setCurrentBasket(basket)
    }, (err) => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
    });
    this.spinner.hide()
  }

  ngOnInit() {

    this.spinner.show()
    this.scrollToTop();
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;
        let registrationOption;
        let organisationQuestionsID;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('booking_details_page_title') ? this.pageTitle = this.selectedDepartment.extra.booking_details_page_title : this.pageTitle = "Booking details title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('booking_details_page_text') ? this.bookingDetailsPageText = this.selectedDepartment.extra.booking_details_page_text : null;
          // Get the registration option
          this.selectedDepartment.extra.hasOwnProperty('registration_option') ? registrationOption = this.selectedDepartment.extra.registration_option : null;
          // Get the organistion customer questions
          this.selectedDepartment.extra.hasOwnProperty('organisations_questions') ? organisationQuestionsID = this.selectedDepartment.extra.organisations_questions : null;
        }

        //     // If the register option is set to complete a full registration then capture the following details
        if (registrationOption.toLowerCase() === 'register complete details') {
          this.requiresFullRegistration = true;
        }

        this.departmentService.getLocation().then(location => {
          this.selectedLocation = location;

          this.createBasket(selectedDepartment, location)

        });

        // Get the logged in member so we can populate the details on the customer form and disable them
        this.memberService.getMember().then(member => {

          this.customerDetailsForm.controls.email_address.setValue(member.email);
          this.customerDetailsForm.controls.email_address.disable();

          if (this.requiresFullRegistration) {
            this.customerDetailsForm.controls.first_name.setValue(member.first_name);
            this.customerDetailsForm.controls.first_name.disable();
            this.customerDetailsForm.controls.last_name.setValue(member.last_name);
            this.customerDetailsForm.controls.last_name.disable();
          } else {
            // Get the customer questions
            this.departmentService.getCustomerQuestions().then(questions => {
              let questionIds = organisationQuestionsID.split(',');
              // Reverse the order of the questions to what it should be
              questions.slice().reverse().forEach(question => {
                let exists = questionIds.some(id => id === question.id.toString());
                if (exists) {
                  const memberAnswer = member.answers.filter(answer => answer.question_id == question.id);
                  this.customerDetailsForm.addControl(question.name, new FormControl(true));
                  this.customerDetailsForm.controls[`${question.name}`].setValue(memberAnswer[0].answer);
                  this.customerDetailsForm.controls[`${question.name}`].disable();
                  this.organisationQuestions.push(memberAnswer[0]);
                }
              });
            });
          }
        });

        // Get the selected service
        this.servicesService.get().then(service => {
          this.selectedService = service;

          if (this.selectedService.$has('questions')) {

            // Get the questions
            this.selectedService.$get('questions').then((res) => {
              this.bookingQuestions = res.questions;
              this.datastoreService.bookingQuestions = this.bookingQuestions;

              for (let index = 0; index < res.questions.length; index++) {
                this.questionIdToIndex[res.questions[index].id] = index;
                const question = res.questions[index];
                question.visible = true;
                question.reference = question.id;
                if (!question.admin_only) {
                  if (!question.settings.hasOwnProperty('conditional_question')) {
                    question.visible = true;
                  }

                  const questionControl = question.id;

                  if (question.default == 1 && question.detail_type == 'check' || question.default == 1 && question.detail_type == 'check-price') {
                    if (question.required) {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(true, Validators.required));
                    } else {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(true));
                    }
                  } else {
                    if (question.required) {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl('', Validators.required));
                    } else {
                      this.bookingQuestionsForm.addControl(questionControl, new FormControl(''));
                    }
                  }
                }
              }
              // Repopulate the booking questions when coming back
              this.datastoreService.bookingForm ? this.repopulateQuestions() : null;
            });
          }
        });
      }
      this.openCalender();

    });
  }

  repopulateQuestions() {
    this.bookingQuestionsForm = this.datastoreService.bookingForm;

    // Repopulate the file input field
    if (this.bookingQuestionsForm.controls[588]) {
      const file = this.bookingQuestionsForm.controls[588].value;
      this.selectedFile = file.name;
      this.form['588'].setValue(file, { emitModelToViewChange: false });
      this.bookingQuestionsForm.patchValue({
        fileSource: file
      });
    }
    this.onQuestionChange();
  }

  onQuestionChange() { //does not work
    const bookingQuestions = this.bookingQuestions
    // Conditional questions logic
    for (let index = 0; index < bookingQuestions.length; index++) {
      const q = bookingQuestions[index];
      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty('conditional_question')) {
        // Get the conditional question based on the id set in q
        const conditionalQ = bookingQuestions.filter(question => question.id == q.settings.conditional_question);
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        const option = conditionalQ[0].options.filter(option => option.name == questionControl.value);

        // If the conditional question is visible and the value chosen === to qs conditional answer then show q
        if (conditionalQ.length > 0 && conditionalQ[0].visible && option.length > 0 && q.settings.conditional_answers.hasOwnProperty(option[0].id)) {
          this.form[q.id].enable();
          // If the condiditonal question is important, Hide it
          if (q.important) {
            q.visible = false;
          } else {
            q.visible = true;
          }
        } else {
          q.visible = false;
          this.form[q.id].setValue('');
          this.form[q.id].disable();
        }
      }
    }

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form['588'].setValue(file, { emitModelToViewChange: false });
      this.bookingQuestionsForm.patchValue({
        fileSource: file
      });
    }
  }

  showConditional(question: any): boolean {
    try {
      this.datastoreService.questionAddedPrice = this.getPrice() //keeps this up to date
      if (question.settings.conditional_question !== undefined) {
        const conditionalParent = this.bookingQuestions[this.questionIdToIndex[question.settings.conditional_question.toString()]];
        const conditionalParentAnswer = this.bookingQuestionsForm.value[question.settings.conditional_question.toString()];
        // console.log(conditionalParent,conditionalParentAnswer,question.settings.conditional_question.toString())

        for (const key in question.settings.conditional_answers) {
          const k: any = question.settings.conditional_answers[key];
          if (conditionalParent.detail_type === 'select' || conditionalParent.detail_type === 'select-price') {
            if (k === 0) {
              return false
            } else {
              const answer = conditionalParent.options.filter(option => option.id == key)
              if (answer.length > 0) {
                if (answer[0].name === conditionalParentAnswer.toString()) {
                  return true && this.showConditional(conditionalParent);
                }
              }
            }
          } else {
            if (key.substring(0, 1) === 'c') { // this means it's a checkbox
              if ((question.settings.conditional_answers[key] === '1') === conditionalParentAnswer) {
                return true && this.showConditional(conditionalParent);
              }
            } else {
              if (key === conditionalParentAnswer.toString()) {
                return true && this.showConditional(conditionalParent);
              }
            }
          }
        }
        this.form[question.id].setValue('');
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err)
      return false;
    }
  }

  getPrice() {
    try {
      let price = 0;
      for (let i in this.bookingQuestions) {
        const question: any = this.bookingQuestions[i];
        const formAnswer = this.bookingQuestionsForm.value[question.id];
        if (formAnswer) {
          if (question.detail_type == "select-price") {
            const answer = question.options.filter(option => option.name == formAnswer);
            if (answer.length > 0) {
              if (answer[0].price) {
                price += answer[0].price
              }
            }
          }
          if (question.detail_type == "radio-price") {
            const answer = question.options.filter(option => parseInt(option.id) == formAnswer);
            if (answer.length > 0) {
              if (answer[0].price) {
                price += answer[0].price
              }
            }
          }
          if (question.price) {
            price += question.price
          }
        }
      }
      if (this.datastoreService.bookingType === 'block') {
        const slots = this.datastoreService.recurringBookingSlots;
        price = price * slots.length 
      }
      return price;
    } catch (err) {
      console.log(err)
      return 0;
    }
  }

  // async addItem() {
  //   // Get the selected slot from the previous page
  //   const selectedSlot = await this.availabilityService.getSelectedSlot();

  //   // Get the booking answers and add them to an array ready to add to basket
  //   for (var key in this.bookingQuestionsForm.value) {
  //     //Date of birth answer - Needed to resolve the Daylight saving time issue
  //     if (key === '587') {
  //       const dob = new Date(this.bookingQuestionsForm.value[key]);
  //       this.bookingQuestionsForm.value[key] = new Date(dob.setTime(dob.getTime() - new Date().getTimezoneOffset() * 60 * 1000));
  //     }
  //     if (this.bookingQuestionsForm.value.hasOwnProperty(key) && key !== 'fileSource' && key !== '588') {
  //       this.bookingAnswers.push({ id: key, answer: this.bookingQuestionsForm.value[key] });
  //     }
  //   }
  //   this.datastoreService.bookingAnswers = this.bookingAnswers;
  //   // Add item to the basket
  //   await this.basketservice.addItem(this.selectedLocation, this.selectedService.id, this.selectedService.durations[0], selectedSlot.start, this.bookingAnswers);
  // }

  checkDob(type) {
    let isValid;
    if (type === 'child') {
      isValid = this.dobValidatorService.isChildDOBValid(this.bookingQuestionsForm.value['587']);
      isValid ? this.form['587'].setErrors({ 'invalidChildDob': true }) : null;
    } else {
      isValid = this.dobValidatorService.isAdultDOBValid(this.bookingQuestionsForm.value['587']);
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if the login form is invalid
    const paymentReference = this.bookingQuestionsForm.value[614]
    if (this.bookingQuestionsForm.invalid) {
      return;
    } else {
      // Navigate to the summary page
      // if (!paymentReference && this.selectedService.id !== 49053) {
      this.datastoreService.requiresPayment = true;
      // } else {
      //   this.datastoreService.requiresPayment = false;
      // }
      this.datastoreService.bookingForm = this.bookingQuestionsForm;
      this.datastoreService.customerDetailsForm = this.customerDetailsForm;
      this.spinner.hide();
      // Navigate to the summary page
      this.router.navigate(["/summary"]);
    }
  }

  // Scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  updateTab() { // keep checking to see if the calender is open and then make it tabable
    const markers: any = document.body.getElementsByClassName("mat-calendar-body-cell");
    if (markers.length !== 0) {
      for (let i in markers) {
        if (markers[i].tabIndex !== undefined && markers[i].classList.length !== 3) {
          markers[i].tabIndex = 0;
          markers[i].addEventListener("focus", function () { // these highlight it when we tab over it 
            this.style.backgroundColor = "yellow";
          });
          markers[i].addEventListener("blur", function () {
            this.style.backgroundColor = "white";
          });
          markers[i].addEventListener('keypress', function (e) { // this lets the enter key select a date
            if (e.key === 'Enter') {
              this.click();
            }
          });
        }
      }
    }
    this.openCalender()
  }

  openCalender() {
    setTimeout(() => {
      this.updateTab(),
        100
    });
  }

  formatAsPrice(price: number) {
    return (price / 100).toFixed(2);
  }

  // puts back below next when on a mobile device
  mobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}