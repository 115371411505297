import { Component, OnInit } from '@angular/core';
import { IBreadCrumb } from './breadcrumb.interface';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DepartmentService } from '../services/jrni/department.service';
import { DatastoreService } from 'src/app/services/datastore.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[]
  currentUrl: string;
  hideBreadcrumbs: boolean;
  hideNav: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private departmentService: DepartmentService,
    private dataStore: DatastoreService
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, '');
    this.hideNav = false;
  }


  ngOnInit() {
    if (this.dataStore.hideNavigation) {
      this.hideNav = true;
    }

    this.dataStore.getSouldHideNavigation().subscribe((member: any) => {
      this.hideNav = true;
    });

    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(async (event: NavigationEnd) => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, event.url);

      this.currentUrl = event.url;

      // Set location always visited
      if (this.currentUrl === '/departments' || this.currentUrl === '/account' || this.currentUrl === '/') {
        this.hideBreadcrumbs = true;
      } else {
        this.hideBreadcrumbs = false;
      }

      const selectedDepartment = await this.departmentService.get();

      // Gets the index of the url we are currently on
      let existingIndex = this.breadcrumbs.findIndex(e => `/${e.url}` == event.url);
      if(event.url === "/register"){
        existingIndex = this.breadcrumbs.findIndex(e => `/${e.url}` == "/login");
      }

      // Gets the visited URLs (handles if the users goes back)
      const visitedUrls = this.breadcrumbs.slice(0, existingIndex + 1);

      // Checks the breadcrumbs against the visisted URLS and sets breadcrumb to visited
      this.breadcrumbs.forEach(element => {
        visitedUrls.forEach(visitedElement => {
          if (element.url == visitedElement.url) {
            element.visited = true;
          }
        });
      });
    });
  }

  // Builds the array of breadcrumbs using the router
  buildBreadCrumb(activatedRoute: ActivatedRoute, url: string, breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let newBreadcrumbs = [];
    
    let bookingType = "";
    if(sessionStorage.getItem("bookingType")){
      bookingType = JSON.parse(sessionStorage.getItem("bookingType"));
    }

    for (let index = 0; index < this.router.config.length; index++) {
      const route = this.router.config[index];
      
      if(route.path == "block-booking-confirmation" && bookingType != "block"){
        continue;
      } else if (route.path == "confirmation" && bookingType == "block"){
        continue;
      }

      const breadcrumb: IBreadCrumb = {
        label: route.data ? route.data.breadcrumb : null,
        url: route.path,
        visited: false
      };

      let responseCode;
      activatedRoute.queryParams.subscribe(params => {
        responseCode = params['responseCode'];
      });

      // Set all breadcrumbs to visited
      if (url && url.includes('confirmation') && responseCode && responseCode == '00') {
        breadcrumb.visited = true;
      }

      // Set all breadcrumbs before confirmation to visited
      if (url && url.includes('confirmation') && responseCode && responseCode == '101' && breadcrumb.url != 'confirmation') {
        breadcrumb.visited = true;
      }

      if(breadcrumb.label != 'Register'){
        breadcrumb.label ? newBreadcrumbs.push(breadcrumb) : {};
      }
      
    }

    return newBreadcrumbs;
  }

  swapItems(input, index_A, index_B) {
    let temp = input[index_A];

    input[index_A] = input[index_B];
    input[index_B] = temp;
  }

  tabindex(breadcrumb){
    if(!breadcrumb.visited) return -1;
    else return 0;
  }
}
