import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { context } from 'bookingbug-core-js';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private url = 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';

  constructor(private http: HttpClient) { }

  sendEmail(type, bookingData, recipient = null, company) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const senderAddress = "sportspitches@jrni.com";
    let subject = ''
    if (type === 'CANCEL') {
      subject = 'Your appointment with the Transport Bureau has been cancelled'
    } else if (type === 'AMEND') {
      subject = 'Your appointment with the Transport Bureau has been amended'
    } else if (type === 'MOVED') {
      subject = 'Your appointment with the Transport Bureau has been moved'
    } else if (type === 'CONFIRMED') {
      subject = 'Your appointment with the Transport Bureau has been confirmed'
    }

    const data: EmailType = {
      host: `${context.apiUrl}`,
      companyid: company.id,
      slotid: bookingData.id,
      recipient: recipient != null ? recipient : bookingData.email,
      type: type,
      senderAddress,
      subject
    }
    return this.http.post(`${this.url}/sendCommunicationEmail`, data, { headers });
  }

}
export class EmailType {
  host: String;
  companyid: String;
  slotid: String;
  recipient: String;
  type: String = 'CANCEL' || 'AMEND' || 'MOVED' || 'CONFIRMED';
  senderAddress: String;
  subject: String;
};