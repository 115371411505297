import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AlertService } from 'src/app/_alert';
import { DatastoreService } from 'src/app/services/datastore.service';
import * as moment from 'moment';
import { MemberService } from 'src/app/services/jrni/member.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  selectedDepartment;
  pageTitle;
  calendarPageText;
  minDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  selectedService;
  selectedLocation;
  slots: [];
  selectedSlot;
  availableDays: any;
  component: String;
  bookingsLimit: String;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    private basketService: BasketService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private memberService: MemberService
  ) {
  }

  routerGoBack() {
    this.router.navigate(['/services']);
  }

  restartJourney() {
    this.router.navigate(['/services']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.component = "calendar";
    this.spinner.show();
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Set the current date to today for the calendar to default to today
        this.currentDate = new Date();
        this.selectedDate = this.currentDate;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('calendar_page_title') ? this.pageTitle = this.selectedDepartment.extra.calendar_page_title : this.pageTitle = "Calendar title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('calendar_page_text') ? this.calendarPageText = this.selectedDepartment.extra.calendar_page_text : null;
        }

        // Retrieve the selected service
        this.servicesService.get().then(service => {
          this.selectedService = service;

          this.bookingsLimit = this.selectedService.extra.bookings_per_customer;

          //Retrieve the selected location
          this.departmentService.getLocation().then(location => {

            // Clear the basket
            this.basketService.deleteAllBaskets(location).catch(() => {
              this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
            });
            this.selectedLocation = location;
          });
        });
      }
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  continue() {
    if (this.datastoreService.bookingType == 'block') {
      if(this.datastoreService.recurringBookingSlots.length > 0) {
        this.router.navigate(['/login']);
      }
      return;
    }

    this.availabilityService.getSelectedSlot().then(slot => {
      if (slot) {
        const selectedDate = new Date(slot.datetime).toISOString().split('T')[0];
        // Check how many bookings the user has for the selected date
        this.memberService.getBookingsSelectedDate(selectedDate).then(bookings => {
          if (bookings.length >= Number(this.bookingsLimit)) {
            this.alertService.warn(this.translateService.instant('CALENDAR.BOOKINGS_LIMIT_REACHED', { limit: this.bookingsLimit }))
            this.scrollToTop();
          } else {
            // Store the selected date
            // this.datastoreService.selectedDate = this.selectedDate;
            // Store the selected slot
            this.datastoreService.selectedSlot = slot;
            this.router.navigate(['/login']);
          }
        });
      } else {
        this.alertService.warn(this.translateService.instant('CALENDAR.SLOT_WARNING'));
        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  // puts back below next when on a mobile device
  mobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}
