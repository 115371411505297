import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './footer/footer/footer.component';
import { HeaderComponent } from './header/header/header.component';
import { DepartmentsComponent } from './journey/departments/departments.component';
import { LocationComponent } from './journey/location/location.component';
import { LoginComponent } from './journey/login/login.component';
import { ServicesComponent } from './journey/services/services.component';
import { AlertModule } from './_alert';
import { CalendarComponent } from './journey/calendar/calendar.component';
import { BookingDetailsComponent } from './journey/booking-details/booking-details.component';
import { SummaryComponent } from './journey/summary/summary.component';
import { ConfirmationComponent } from './journey/confirmation/confirmation.component';
import { LeafletMapComponent } from './leaflet-map/leaflet-map.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './journey/calendar/custom-date-adapter';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { MainAccountComponent } from './account-journey/main-account/main-account.component';

// Material
import { MatCheckboxModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './journey/register/register.component';
import { SanitiseHtmlPipe } from './pipes/sanitise-html.pipe';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MemberBarComponent } from './member-bar/member-bar.component';
import { MyAccountComponent } from './account-journey/my-account/my-account.component';
import { ChangePasswordComponent } from './account-journey/change-password/change-password.component';
import { UpcomingBookingsComponent } from './account-journey/upcoming-bookings/upcoming-bookings.component';
import { PastBookingsComponent } from './account-journey/past-bookings/past-bookings.component';
import { AccountLoginComponent } from './account-journey/account-login/account-login.component';
import { ModalComponent } from './modal/modal.component';
import { MatCalendarComponent } from './journey/mat-calendar/mat-calendar.component';
import {  MAT_DATE_LOCALE } from '@angular/material';
import { PaymentComponent } from './journey/payment/payment.component';
import { VerifyPaymentComponent } from './journey/verify-payment/verify-payment.component';
import { BlockBookingConfirmationComponent } from './journey/block-booking-confirmation/block-booking-confirmation.component';
import { BlockPurchaseReviewComponent } from './journey/block-purchase-review/block-purchase-review.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

// required for AOT compilation
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LocationComponent,
    DepartmentsComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    BreadcrumbsComponent,
    ServicesComponent,
    CalendarComponent,
    BookingDetailsComponent,
    SummaryComponent,
    ConfirmationComponent,
    LeafletMapComponent,
    BookingSummaryComponent,
    RegisterComponent,
    MemberBarComponent,
    SanitiseHtmlPipe,
    MainAccountComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    UpcomingBookingsComponent,
    PastBookingsComponent,
    AccountLoginComponent,
    ModalComponent,
    MatCalendarComponent,
    PaymentComponent,
    VerifyPaymentComponent,
    BlockBookingConfirmationComponent,
    BlockPurchaseReviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      'loader': {
        'provide': TranslateLoader,
        'useFactory': HttpLoaderFactory,
        'deps': [HttpClient]
      }
    }),
    AlertModule,
    NgbModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent],
})
export class AppModule { }

