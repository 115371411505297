import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  childrenDepartments: [];

  constructor(
    private departmentService: DepartmentService,
    protected alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    this.departmentService.getParentDepartment().then(res => {
      this.spinner.hide();
      // Get the child companies
      this.departmentService.getChildDepartments(res).then(children => {
        this.childrenDepartments = children.filter(company => company.hasOwnProperty('extra') && company.extra.hasOwnProperty('live_status') && company.extra.live_status.toLowerCase() !== 'disabled')
        this.spinner.hide();
      }, err => {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        this.spinner.hide();
      });
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
      this.spinner.hide();
    });
  }

  selectDepartment(department) {
    this.departmentService.set(department);
    // If the department has the setting required login then navigate to login else to services page
    if (department.hasOwnProperty('extra') && department.extra.hasOwnProperty('login_required')) {
      department.extra.login_required.toLowerCase() === 'yes' ? this.router.navigate(['/login']) : this.router.navigate(['/services']);
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }
}
