import { Injectable } from '@angular/core';
import { BBService } from 'bookingbug-core-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor() { }

  public bookingQuestions = [];

  public bookingForm;

  public selectedDate;

  public selectedSlot;

  public locations = [];

  public bookingAnswers;

  public requiresPayment;

  public customerQuestions = [];

  public customerDetailsForm;

  public client;

  public address;

  public latitude;

  public longitude;

  public selectedDuration;

  public totalPrice;

  public questionAddedPrice;

  public isAccounts;

  public accountBooking;

  public selectedLocation;

  public selectedService;

  public booking;

  public bookingType;

  public skipPayment;

  public recurringBookingSlots = [];

  public recurringDates: any[];

  public hideNavigation: boolean = false;

  public resources = [];

  public selectedResource: any;

  private shouldHideNavigation = new Subject<any>();

  async getClient() {
    return this.client;
  }

  getSouldHideNavigation() {
    return this.shouldHideNavigation.asObservable();
  }

  setSouldHideNavigation(data) {
    this.shouldHideNavigation.next(data);
  }

  checkShouldHideNavigation() {
    if (localStorage.getItem('hideNavigation')) {
      const hideNavigation = JSON.parse(localStorage.getItem('hideNavigation'));
      if (hideNavigation) {
        this.hideNavigation = true;
        this.setSouldHideNavigation(true);
      }
      else {
        this.hideNavigation = false;
        this.setSouldHideNavigation(false);
      }
    }
    else {
      this.hideNavigation = true;
      localStorage.setItem('hideNavigation', JSON.stringify(true));
      this.setSouldHideNavigation(true);
    }
  }
}
