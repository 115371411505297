import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { LeafletMapComponent } from 'src/app/leaflet-map/leaflet-map.component';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  // Access the leaflet components properties
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  selectedDepartment;
  pageTitle;
  locationPageText;
  locations;
  selectedLocation;
  mapsRequired: boolean;

  invalidParams: boolean = false;
  category: any;
  bookingType: String = "";
  categories: [];
  preSelectedLocation = false;


  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService,
    private activatedRoute: ActivatedRoute
  ) { }

  routerGoBack() {
    // If location is before services then redirect back to locations else redirect back to departments
    if (this.selectedDepartment.extra.service_or_location_first.toLowerCase() === "location") {
      this.router.navigate(['/services']);
    } else {
      this.router.navigate(['/services']);
    }
  }

  restartJourney() {
    this.router.navigate(['/services']);
  }

  ngOnInit() {

    this.scrollToTop();
    this.spinner.show();

    this.departmentService.getParentDepartment().then(res => {
      this.selectedDepartment = res;

      this.departmentService.set(res);
      this.departmentService.get().then(selectedDepartment => {
        if (selectedDepartment === undefined) {
          // this.restartJourney();
        } else {
          this.selectedDepartment = selectedDepartment;

          // Get the configurable data
          if (this.selectedDepartment.hasOwnProperty('extra')) {
            // Set the title on the login page to the title from the business question
            this.selectedDepartment.extra.hasOwnProperty('location_page_title') ? this.pageTitle = this.selectedDepartment.extra.location_page_title : this.pageTitle = "Location title not set";
            // Set the extra text on the page to the text taken from the business question
            this.selectedDepartment.extra.hasOwnProperty('location_page_text') ? this.locationPageText = this.selectedDepartment.extra.location_page_text : null;
            // Check if maps are required
            this.selectedDepartment.extra.hasOwnProperty('maps') ? this.selectedDepartment.extra.maps.toLowerCase() === 'disabled' ? this.mapsRequired = false : this.mapsRequired = true : null;
          }

          // Get the children departments (locations) from the selected department
          this.departmentService.getSelectedDepartmentChildren().then(locations => {

            this.setLocations(locations);

            this.spinner.hide();
          }, err => {
            this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
            this.spinner.hide();
          });
        }
      }, err => {
        this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
        this.spinner.hide();
      });

      this.spinner.hide();
    }, err => {
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
      this.spinner.hide();
    });

  }

  async setLocations(locations) {
    const locationsList = [];

    this.categories = await this.selectedDepartment.$getCategories();

    this.activatedRoute.queryParams
      .subscribe(async params => {
        if (params.category) {
          const category: any = this.categories.filter((category: any) => category.name.toLowerCase() == params.category.toLowerCase());
          this.category = category[0].id;
          sessionStorage.setItem("category", JSON.stringify(this.category));
        }

        if (params.bookingtype) {
          this.bookingType = params.bookingtype;
          sessionStorage.setItem("bookingType", JSON.stringify(this.bookingType));
        }

        if (params.location) {
          const selectedLocation = parseInt(params.location);

          for (const location of locations) {
            const services = await location.$getServices();
            if (services) {
              await services.forEach(async service => {
                if (service.category_id == this.category) {
      
                  if(selectedLocation == location.id){
                    this.preSelectedLocation = true;
                    sessionStorage.setItem("location", JSON.stringify(location));
                    this.selectedLocation = location;
                    this.continue();
                  }
                }
              })
            }
      
          }

        }

      });

    if (sessionStorage.getItem("category")) {
      this.category = JSON.parse(sessionStorage.getItem("category"));
    }
    if (sessionStorage.getItem("bookingType")) {
      this.bookingType = JSON.parse(sessionStorage.getItem("bookingType"));
    }

    if (this.category === 0 || this.bookingType === "") {
      this.invalidParams = true;
      this.spinner.hide();
      window.location.href = "https://www.lbhf.gov.uk/sport/sports-bookings-and-facilities";
      return;
    }

    for (const location of locations) {
      const services = await location.$getServices();
      if (services) {
        await services.forEach(async service => {
          if (service.category_id == this.category) {

            const exists = locationsList.filter(loc => loc.id == location.id);
            if (exists.length == 0) {
              locationsList.push(location)

            }
          }
        })
      }

    }

    this.departmentService.setLocationList(locationsList);
    this.locations = locationsList;
    this.datastoreService.locations = locationsList;

    if(!this.preSelectedLocation){
      setTimeout(() => {
        this.updateTab(),
          100
      });
    }

  }

  updateTab() {
    const markers: any = document.body.getElementsByClassName("leaflet-marker-icon");
    for (let i in markers) {
      if (markers[i].tabIndex !== undefined) {
        markers[i].tabIndex = -1;
        markers[i].alt = "location marker";
      }
    }
    const zoomIn : any = document.body.getElementsByClassName("leaflet-control-zoom-in")[0];
    const zoomOut : any = document.body.getElementsByClassName("leaflet-control-zoom-out")[0];
    zoomIn.tabIndex = -1;
    zoomOut.tabIndex = -1;
  }

  updateMapView(lat, long) {
    if (this.mapsRequired) {
      this.leafletMapComponent.renderMapLocation(lat, long);
    }
  }

  selectLocation(location) {
    this.selectedLocation = location;
  }

  continue() {
    this.departmentService.getLocation().then(location => {
      if (this.selectedLocation) {
        if (location && location !== this.selectedLocation) {
          this.datastoreService.selectedDate = null;
          this.availabilityService.setSelectedSlot(null);
        }
        this.departmentService.setLocation(this.selectedLocation);

        this.router.navigate(['/services'])

      } else {
        this.alertService.warn("Please select a location before proceeding");

        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }
}
