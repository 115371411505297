import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { ServicesService } from 'src/app/services/jrni/services.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  selectedLocation;
  error = true;
  paymentComplete = false;
  selectedService;

  constructor(private router: Router,
    private basketService: BasketService,
    private departmentService: DepartmentService,
    private memberService: MemberService,
    private datastoreService: DatastoreService,
    private spinner: NgxSpinnerService,
    private servicesService: ServicesService
  ) { }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show()
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.departmentService.getLocation().then(location => {
          if (location === undefined) {
            this.restartJourney()
          } else {
            this.selectedLocation = location
          }
        })
        this.servicesService.get().then((service) => {
          if (service === undefined) {
            this.restartJourney()
          } else {
            this.selectedService = service
          }
        });
        this.spinner.hide();
      }
    })
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  restartJourney() {
    this.router.navigate(['/services']);
  }

  routerGoBack() {
    this.router.navigate(['/summary']);
  }

  completePayment() {
    this.paymentComplete = true;
  }

  async onSubmit() {
    this.spinner.show()

    const noNotifications = false;
    const member = await this.memberService.getMember();

    this.basketService.checkoutBasket(this.selectedLocation, member, noNotifications).then((res) => {
      
      this.spinner.hide()
      
      if(sessionStorage.getItem("bookingType")){
        const bookingType = JSON.parse(sessionStorage.getItem("bookingType"));
        if(bookingType == "block"){
          this.router.navigate(['/block-booking-confirmation']);
        }
      }
      this.router.navigate(['/confirmation']);


    }, (e) => {
      this.error = true;
    this.spinner.hide()
  });

  }

  // puts back below next when on a mobile device
  mobile(){
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

}
