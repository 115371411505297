import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  selectedSlot;
  availableTimesService;
  availableDaysService;

  constructor(
    private http: HttpClient
  ) { }

  async getSlots(location, params) {
    const baseResource = await location.$get('times', params)
    return await baseResource.$get('times');
  }

  async getSlotsTimeData(location, params) {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id
    });

    const apiUrl = `${this.apiServer.api_url}/api/v5/${location.id}/time_data`
    return await this.http.get(apiUrl, { headers, params }).toPromise();
  }

  async setSelectedSlot(slot) {
    this.selectedSlot = slot;
  }

  async getSelectedSlot() {
    return this.selectedSlot;
  }

  async requestAvailibleTimes(location, service, today, maxDate) {
    const params = {
      'company_id': location.id,
      'service_id': service.id,
      'start_date': today,
      'end_date': maxDate
    };
    const baseResource = await location.$get('times', params)
    const slots = await baseResource.$get('times');
    return slots;
  }

  async setAvailableTimes(availableTimes) {
    this.availableTimesService = availableTimes;
  }

  async getTimes() {
    return this.availableTimesService;
  }

  // async requestAvailableDays(location, service, today, maxDate) {
  //   const services = await service.$getChildServices();
  //   const childService = services.filter(service => service.company_id === location.id);
  //   return await childService[0].$get('days', { company_id: location.id, date: today, edate: maxDate });
  // }

  async requestAvailableDays(location, service, today, maxDate) {
    return await service.$get('days', { company_id: location.id, date: today, edate: maxDate });
  }


  async setAvailableDays(availableDays) {
    this.availableDaysService = availableDays;
  }

  async getDays() {
    return this.availableDaysService;
  }

  async requestAvailableDaysService(location, service, today, maxDate) {
    return await service.$get('days', { company_id: location.id, date: today, edate: maxDate });
  }
}
