import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Pipe to take HTML and allow it to be executed

@Pipe({
  name: 'noSanitise'
})
export class SanitiseHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}