import { Injectable, isDevMode  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import { context } from 'bookingbug-core-js';

@Injectable({
  providedIn: 'root'
})
export class CapitaService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  private url = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private path = '/';
  capitaUrlPath = (window.location.hostname.indexOf('staging') !== -1) ? '/payment-staging/' : '/payment/';

  constructor(
    private http: HttpClient
  ) { }

  async invoke(company, booking, purchase, client, description, fundCode, price, vatOptOut) {
    if(!fundCode) {
      fundCode = 'MISC';
    }

    let port = '';
    const ports:any = ['80', '443'];
    if(ports.indexOf(window.location.port) === -1) {
      port = `:${window.location.port}`;
    }

    const body = {
      host: `${context.apiUrl}`,
      company_id: `${company.id}`,
      booking_id: `${booking.id}`,
      purchase_id: `${purchase.id}`,
      client_id: `${client.id}`,
      fund_code: `${fundCode}`,
      description: `${description}`,
      reference: `${AppConfig.settings.payment.reference}`,
      amount: price,
      send_user_details: false, // this adds the billingDetails element to the Capita XML with the card holder name and email pre-populated in the portal
      combine_reference: true,
      add_account_details: true, // This adds the accountName field to the lgItemDetails section of the Capita XML
      vat_opt_out: vatOptOut,
      return_url: `${window.location.protocol}//${window.location.hostname}${port}${this.path}payment-verify?p=${purchase.id}&b=${booking.id}&c=${company.id}`,
      back_url: `${window.location.protocol}//${window.location.hostname}${port}${this.path}payment-verify?p=${purchase.id}&b=${booking.id}&c=${company.id}`
    }

    return this.http.post(`${this.url}${this.capitaUrlPath}capita/invoke`, body).toPromise();
  }

  async query(paymentRef) {
    const body = {
      host: `${context.apiUrl}`,
      scp_id: paymentRef
    };
    return this.http.post(`${this.url}${this.capitaUrlPath}capita/query`, body).toPromise();
  }
  
  async find(purchaseId, companyId) {
    const body = {
      host: `${context.apiUrl}`,
      company_id: `${companyId}`,
      purchase_id: `${purchaseId}`
    }

    return this.http.post(`${this.url}${this.capitaUrlPath}capita/find`, body).toPromise();
  }

    
  async vatExemption(companyId, serviceItems){
    const body = {
      host: `${context.apiUrl}`,
      company_id: `${companyId}`,
      service_items: serviceItems

    };
    return this.http.post(`${this.url}${this.capitaUrlPath}capita/vat-exemption`, body).toPromise();
  }
}
