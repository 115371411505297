import { AfterViewInit, Component, Input } from '@angular/core';
import { DatastoreService } from '../services/datastore.service';
declare let L;
declare var GreyscaleMap: any;

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements AfterViewInit {
  map;
  marker;
  @Input() confirmationPage: boolean;

  constructor(private datastoreService: DatastoreService) { }

  ngAfterViewInit() {
    this.map = L.map('map', { attributionControl: false }).setView([51.501275, -0.174371], 12.4);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Bookinglab'
    }).addTo(this.map);

    this.datastoreService.locations.forEach((location) => {
      const lat = location.address.lat;
      const long = location.address.long;
      this.marker = L.marker([lat, long]).addTo(this.map);
    });

    // add KML layer
    fetch('./assets/HF-borough-boundary.kml')
      .then(res => res.text())
      .then(kmltext => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmltext, "text/xml");

        const track = new L.KML(kml);
        this.map.addLayer(track);

        // Adjust map
        const bounds = track.getBounds();
        this.map.fitBounds(bounds);
      })
  }

  renderMapLocation(lat, long) {
    this.marker.setLatLng([lat, long]).update();

    this.map.flyTo([lat, long], 15, {
      animate: true,
      duration: 0.6
    });
  }
}
