import { Injectable } from '@angular/core';
import { BBModel, BBService } from 'bookingbug-core-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { context } from 'bookingbug-core-js';
import { AppConfig } from 'src/app/app.config';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { DatastoreService } from 'src/app/services/datastore.service';
import { MemberService } from './member.service';
import { ServicesService } from './services.service';
import { BasketService } from './basket.service';
import { AvailabilityService } from './availability.service';
import { LoginService } from './login.service';
import * as _ from "lodash";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  private url = (window.location.hostname == 'localhost') ? 'http://localhost:4201' : 'https://api.bookinglab.co.uk';
  private token = 'e425864ceda99bbd35a164f47e1c97e7a33d457c96dc5688bc019029800cc612';
  selectedService: any;
  selectedBooking: any;
  selectedLocation: any;

  constructor(
    private http: HttpClient,
    private departmentService: DepartmentService,
    private dataStore: DatastoreService,
    private memberService: MemberService,
    private servicesService: ServicesService,
    private basketService: BasketService,
    private availabilityService: AvailabilityService,
    private loginService: LoginService
  ) { }

  async getBooking(member, bookingId) {
    const bookings = await this.getBookings(member, [], 1);
    for (const booking of bookings) {
      if (booking.id == bookingId) {
        return booking;
      }
    }
    return false;
  }

  async getBookings(member, bookings, page) {
    const bookingDate = moment().subtract('1', 'M');
    const params = { page: page, include_cancelled: false, start_date: bookingDate.format('YYYY-MM-DD'), per_page: 30 };
    const returnedBookings = await BBModel.Member.Booking.$query(member, params);
    for (const booking of returnedBookings) {
      bookings.push(booking);
    }
    if (returnedBookings.length == 30) {
      ++page;
      return await this.getBookings(member, bookings, page);
    }
    else {
      return bookings;
    }
  }

  async cancelBooking(member, booking) {
    return await BBModel.Member.Booking.$cancel(member, booking);
  }

  async updateBooking(booking) {
    return await BBModel.Member.Booking.$update(booking);
  }

  async cancelMultiBookings(booking) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };

    const body = {};

    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      "booking_id": booking.id,

    }

    return await this.http.post(`${this.url}/cancelMultiBookings`, params, { headers }).toPromise();
  }

  async sendAdminEmail(booking) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };

    const body = {};

    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      booking_id: booking.id,

    }

    return await this.http.post(`${this.url}/sendAdminEmail`, params, { headers }).toPromise();
  }

  // async rescheduleBooking(booking, member, slot) {
  //   const headers: HttpHeaders = new HttpHeaders({
  //     'App-Id': this.core.app_id,
  //     'App-Key': this.core.app_key,
  //     'Auth-Token': localStorage.getItem("auth_token")
  //   });
  //   const params = {
  //     date: booking.datetime.format("YYYY-MM-DD"),
  //     time: booking.datetime.format("HH:mm"),
  //   }
  //   const apiUrl = `${this.apiServer.api_url}/api/v5/${booking.company_id}/members/${member.id}/bookings/${booking.id}/reschedule`;
  //   return await this.http.put(apiUrl, params, { headers }).toPromise();
  // }

  async cancelBlock(booking) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };

    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      "blockData": {
        "booking_id": booking.id
      }
    }

    return await this.http.post(`${this.url}/deleteBlock`, params, { headers }).toPromise();
  }

  async rescheduleBooking(booking, newTime) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      "moveBookingData": {
        "bookingId": `${booking.id}`,
        notify: "",
        date: newTime.format("YYYY-MM-DD"),
        time: newTime.format("HH:mm"),
        duration: booking.duration,
        questions: booking.questions,
      }
    }

    return await this.http.post(`${this.url}/rescheduleBooking`, params, { headers }).toPromise();
  }


  async findBookingBlocks(booking) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      booking_id: `${booking.id}`,
      start_date: `${booking.datetime.toISOString()}`,
      end_date: `${booking.end_datetime.toISOString()}`
    }

    return await this.http.post(`${this.url}/findBookingBlocks`, params, { headers }).toPromise();
  }

  async moveBlockedBooking(booking, newDatetime, duration) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const params = {
      host: `${context.apiUrl}`,
      company_id: `${booking.company_id}`,
      "blockData": {
        "booking_id": `${booking.id}`,
        "datetime": `${newDatetime}`,
        "duration": duration,
        "notify": false,
        "resource_id": `${booking.resource_id}`,
      }
    }

    return await this.http.post(`${this.url}/moveBlock`, params, { headers }).toPromise();
  }

  async addExtraResources(slot, booking, resource_id) {
    try {
      if (slot) {
        let slotsNeeded = (slot.quantity);
        return new Promise(resolve => {
          // -1 bc one of the needed slots is the booked one
          for (let index = 0; index < slotsNeeded - 1; index++) { 
            const associatedSlot = slot.associatedSlots[index];
            if(associatedSlot.resource_id.toString() !== resource_id.toString()){ // don't block the booked slot
            this.blockBooking(index, slot, booking, resource_id);
            } else {
              slotsNeeded++ // need to go find a slot that is not the booked one
            }
          }
          localStorage.removeItem('selectedSlot');
          resolve(slot);
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async blockBooking(index, blockSlot, booking, resource_id) {
    this.selectedService = JSON.parse(localStorage.getItem('selectedService'));
    this.selectedBooking = booking;
    this.selectedLocation = booking.company_id;

    const slot = blockSlot.associatedSlots[index];
    const tempDateTime = new Date(blockSlot.datetime);
    const endDate = new Date(tempDateTime.getTime() + this.selectedBooking.duration * 60000);

    if (slot && slot.resource_id != resource_id) {
      const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
      const body = {
        "host": `${context.apiUrl}`,
        "company_id": `${this.selectedLocation}`,
        "type": "resources",
        "resource_id": `${slot.resource_id}`,
        "start_datetime": `${blockSlot.datetime}`,
        "end_datetime": `${endDate.toISOString()}`,
        "private_note": `Tied to booking: ${this.selectedBooking.id}`
      }
      try {
        return await this.http.post(`${this.url}/blockBooking`, body, { headers }).toPromise(); // TODO switch all calls to api.bookinglab.co.uk when endpoints set
      }
      catch (err) {
        console.log(err);
        return null;
      }
    }
  }

  decodeBookingRef(ref: string): number {
    const findArray = ['a', 'b', 'c', '|', '-', '_', 'x', 'y', 'z', ':'];
    const replaceArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    if (this.rescheduleBooking.length > 0) {
      const replaced = [];
      const parts = ref.split('');
      for (const part of parts) {
        const idx = findArray.indexOf(part);
        const replace = replaceArray[idx];
        replaced.push(replace);
      }
      return parseInt(replaced.join(''));
    }
    return 0;
  }

  async getBookingFromId(longId, company) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "booking_id": `${longId}`,
      "include_purchases": true
    }

    const booking = await this.http.post(`${this.url}/loadBooking`, body, { headers }).toPromise();
    if (booking) {
      return booking;
    }
    return false;
  }

  async loadBooking(booking, purchase, companyId) {
    context.apiUrl = AppConfig.settings.apiServer.api_url;
    context.apiVersion = AppConfig.settings.apiServer.version;
    const res = await this.departmentService.getParentDepartment();

    const company = await BBService.company.query(parseInt(companyId), { excludeChildCompanies: true });

    this.departmentService.set(company);

    // Set address
    const address = await company.$getAddress();
    if (address) {
      this.dataStore.latitude = address.lat;
      this.dataStore.longitude = address.long;
    }

    // get the member
    this.dataStore.client = booking['_embedded']['client'];

    // get the service
    try {
      const service = await BBService.service.queryById(companyId, booking['service_id']);
      this.servicesService.set(service);
      this.dataStore.selectedService = service;
    }
    catch (err) {
      console.log(err);
    }

    // Get the booking
    const bookings = purchase['_embedded']['bookings'];
    return true;
  }
}



function blockBooking(index: any) {
  throw new Error('Function not implemented.');
}

function index(index: any) {
  throw new Error('Function not implemented.');
}

