import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services/jrni/services.service';
import { DepartmentService } from '../services/jrni/department.service';
import { AvailabilityService } from '../services/jrni/availability.service';
import { DatastoreService } from '../services/datastore.service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {
  resourceSelection = AppConfig.settings.resourceSelection;
  selectedService;
  selectedLocation;
  selectedResource;
  selectedSlot;
  selectedDuration;
  totalPrice;
  isBlockBooking = false; 

  constructor(
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private availabilityService: AvailabilityService,
    public datastoreService: DatastoreService
  ) { }

  ngOnInit() {
    this.selectedDuration = this.datastoreService.selectedDuration;
    this.selectedResource = this.datastoreService.selectedResource;
    
    if(this.selectedResource){
      this.selectedResource = this.datastoreService.resources.filter(res => res.resource_id == this.selectedResource)[0];
    } else {
      // this.datastoreService.selectedResource = this.datastoreService.resources.length > 0 ? this.datastoreService.resources[0].resource_id : "";
    }
    
    if (this.datastoreService.bookingType === 'block') {
      this.isBlockBooking = true;
    }
    // Get the selected service
    this.servicesService.get().then(service => {
      this.selectedService = service;
    });

    // Get the selected location
    this.departmentService.getLocation().then(location => {
      this.selectedLocation = location;
    });

    // Get the selected slot
    if (this.datastoreService.selectedSlot) {
      this.availabilityService.getSelectedSlot().then(slot => {
        this.selectedSlot = slot;
      });
    }
  }

  checkUndefined(val){
    if(val){
      return val;
    } else{
      return 0;
    }
  }

  sortby(recurringDates) {
    return recurringDates.sort((a, b) => {
      return a.date - b.date;
    });
  }
}
