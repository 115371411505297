import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  currentBasket;
  purchase;
  currentItem;
  resourceSelection = AppConfig.settings.resourceSelection;

  constructor(
  ) { }

  async createBasket(selectedLocation) {
    const company = selectedLocation;
    this.currentBasket = await company.$post('baskets', { company_id: company.id });
    return this.currentBasket;
  }

  setCurrentBasket(basket) {
    this.currentBasket = basket
  }

  async getCurrentBasket(selectedLocation) {
    const company = selectedLocation;
    const baseBasketResource = await company.$get('baskets', { company_id: company.id });
    const baskets = await baseBasketResource.$get('baskets');
    this.currentBasket = baskets[baskets.length - 1];
    return baskets[baskets.length - 1];
  }

  async deleteAllBaskets(selectedLocation) {
    const company = selectedLocation;
    if (!company.$has('baskets')) {
      return null;
    }

    const baskets = await this.checkForExistingBaskets(selectedLocation);

    if (baskets.length >= 1) {
      for (const basket of baskets) {
        new Promise(() => {
          company.$del('baskets', { basket_id: basket.id }).then(() => {
            Promise.resolve();
          });
        });
      }
    }
  }

  async checkForExistingBaskets(selectedLocation) {
    const company = selectedLocation;
    if (company.$has('baskets')) {
      const baseBasketResource = await company.$get('baskets', { company_id: company.id });
      const baskets = await baseBasketResource.$get('baskets');
      return baskets;
    }
  }

  async addItem(selectedLocation, service_id, duration, start, questions, resource_id) {
    const company = selectedLocation;
    if (company.$has('baskets')) {

      try {
        resource_id = this.resourceSelection ? resource_id : null
        const item = await this.currentBasket.$post('service_items', {}, {
          service_id,
          duration,
          start,
          company_id: company.id,
          basket_id: this.currentBasket.id,
          questions,
          resource_id
        });
        this.currentItem = item;
        return item;
        
      } catch (error) {
        return "Error";
      }
    }
  }
  
  getCurrentItem() {
    return this.currentItem;
  }

  async checkoutBasket(selectedLocation, client, notifications) {
    const company = selectedLocation;
    if (company.$has('baskets')) {
      const purchase = await this.currentBasket.$post('checkout', {}, {
        client,
        basket_id: this.currentBasket.id,
        no_notifications: notifications
      });
      this.purchase = purchase;
    }
    return this.purchase;
  }

  async getPurchase() {
    return this.purchase;
  }
  
  async delete(company, basket) {
    if (!company.$has('baskets')) return null;
    return company.$del('baskets', { basket_id: basket.id });
  }
}